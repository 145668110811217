import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { getThemeConfig } from '../../styles/theme';
import { PercentageTile, ProgressTile } from '../../components/stats';
import { useDataProvider } from "react-admin";
import _Skeleton from '@material-ui/lab/Skeleton';
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import qs from "query-string";

const themeConfig = getThemeConfig();

export default () => {
    const dataProvider = useDataProvider();
    const refreshTriggered = useSelector((state: any) => state?.admin?.ui?.viewVersion);
    const [result, setResult] = useState<DataResponse>();
    const { location } = useHistory();
    const search = qs.parse(location?.search);

    useEffect(() => {
        dataProvider.getOne("report", { id: "activity-summary" })
            .then(({ data }: Response) => setResult(data))
            .catch((e: any) => console.error("Error fetching dashboard... ", e))
    }, [dataProvider, setResult, refreshTriggered]);


    // hide if there are any filters present
    if (search.filter && search?.filter?.length > 2) {
        return null;
    }

    if (!result) {
        return (
            <Wrapper>
                <Skeleton variant="rect" animation="wave" width={"100%"} height={100} />
                <Skeleton variant="rect" animation="wave" width={"100%"} height={100} />
                <Skeleton variant="rect" animation="wave" width={"100%"} height={100} />
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            <Double>
                <ProgressTile
                    title={result.checkIns.total ? `Successful responses today` : `There have been no responses today`}
                    amount={result.checkIns.successful}
                    total={result.checkIns.total}
                    background={themeConfig.img.stats1}
                />
            </Double>
            <Single>
                <PercentageTile
                    title="ID Verified"
                    amount={result.idVerified.successful}
                    total={result.idVerified.total}
                    background={themeConfig.img.stats2}
                />
            </Single>
            <Single>
                <PercentageTile
                    title="Fit for Work"
                    amount={result.fitForWork.successful}
                    total={result.fitForWork.total}
                    background={themeConfig.img.stats3}
                />
            </Single>
        </Wrapper>
    )
}

type Response = {
    data: DataResponse;
};

type DataResponse = {
    checkIns: DataResponseCounts;
    idVerified: DataResponseCounts;
    fitForWork: DataResponseCounts;
}

type DataResponseCounts = {
    successful: number;
    total: number;
}

const Wrapper = styled.div`
	color: ${themeConfig.color.titleText};
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    min-height: 80px;

    

    @media only screen and (max-width: 600px) {
        margin-bottom: 16px;
        flex-direction: column;
		padding: 0 12px 12px;
    }
`

const FlexWrapper = styled.div`
    margin: 0px 12px;
    flex-basis: 100px;
    flex-shrink: 1;
    flex-grow: 2;

    &:first-child {
        margin-left: 0px;
    }

    &:last-child {
        margin-right: 0px;
    }

    @media only screen and (max-width: 600px) {
        margin: 12px;

        &:first-child {
            margin-left: 12px;
        }

        &:last-child {
            margin-right: 12px;
        }
    }
`

const Single = styled(FlexWrapper)``

const Double = styled(FlexWrapper)`
    flex-grow: 5;
`

const Skeleton = styled(_Skeleton)`
    border-radius: 8px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.05);
`;