import React, { HTMLAttributes } from "react"
import styled from "styled-components"
import { Text } from "../atoms"

interface Props extends HTMLAttributes<HTMLDivElement> {
    currentPage: number
    pageSize: number
    totalCount: number
    onNextClicked: () => void
    onPreviousClicked: () => void
}

const Pager: React.FC<Props> = ({currentPage, pageSize, totalCount, onNextClicked, onPreviousClicked, ...props}) => {
    
    const firstItem = (totalCount === 0) ? 0
                                         : ((currentPage - 1) * pageSize) + 1
    const lastItem = (firstItem + pageSize - 1)
    const isFirstPage = (currentPage === 1)
    const isLastPage = (lastItem >= totalCount)

    return <Wrapper {...props}>
        <Button isVisible={!isFirstPage} weight="medium" onClick={onPreviousClicked}>{"< Prev"}</Button>
        <Text>{`${firstItem} - ${isLastPage ? totalCount : lastItem}`}</Text>
        <TextWithMargin opacity="weak">of</TextWithMargin>
        <Text>{totalCount}</Text>
        <Button isVisible={!isLastPage} weight="medium" onClick={onNextClicked}>{"Next >"}</Button>
    </Wrapper>
}

export default Pager

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const Button = styled(Text)<{isVisible: boolean}>`
    
    visibility: ${props => props.isVisible ? "visible" : "hidden"};
    
    &:hover {
        cursor: pointer;
    }

    :first-child {
        margin-right: 20px
    }

    :last-child {
        margin-left: 20px
    }
`

const TextWithMargin = styled(Text)`
    margin: 0 5px;
`