import React, { HTMLAttributes } from "react"
import styled from "styled-components"
import { BarChartCard as _BarChartCard, ProgressCircleCard as _ProgressCircleCard } from "."
import { Map } from "../molecules"
import { Location } from "../../types"
import { Color } from "../../styles/color"

interface Props extends HTMLAttributes<HTMLDivElement> {
    mapDefaultCenter: Location
    mapCenter: Location
    mapZoom: number
    mapMarkers: (Location & { color?: "red" | "green" | "blue" })[]
}

const ValveSensorDummyData: React.FC<Props> = ({mapDefaultCenter, mapCenter, mapZoom, mapMarkers, ...props}) => {

    return <Wrapper {...props}>

        <Map defaultCenter={mapDefaultCenter}
             center={mapCenter ?? mapDefaultCenter}
             zoom={mapZoom}
             markers={mapMarkers}
             loadingElement={<ContainerElement />}
             containerElement={<ContainerElement />}
             mapElement={<ContainerElement />}
             googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC_1Yl3_XRRLfHLewTmKBXe1JA-A-n96bg&v=3.exp&libraries=geometry,drawing,places"/>

        <BarChartCard isLoading={false}
                      title="Breached DMA Status"
                      subTitle="Most common device failures"
                      hasLink={false}
                      yAxisIsPercentage={false}
                      barBackgroundColor={Color.red4}
                      hasList={false}
                      listItems={["AAA-12345", "BBB-09876", "CCC-55555"]}
                      chartItems={[
                          {label: "Jan", backgroundValue: 31, foregroundValue: 26},
                          {label: "Feb", backgroundValue: 40, foregroundValue: 38},
                          {label: "Mar", backgroundValue: 53, foregroundValue: 42},
                          {label: "Apr", backgroundValue: 55, foregroundValue: 52},
                          {label: "May", backgroundValue: 58, foregroundValue: 53},
                          {label: "Jun", backgroundValue: 70, foregroundValue: 51},
                          {label: "Jul", backgroundValue: 74, foregroundValue: 66},
                          {label: "Aug", backgroundValue: 92, foregroundValue: 71},
                          {label: "Sep", backgroundValue: 101, foregroundValue: 78},
                          {label: "Oct", backgroundValue: 120, foregroundValue: 104},
                          {label: "Nov", backgroundValue: 122, foregroundValue: 106},
                          {label: "Dec", backgroundValue: 122, foregroundValue: 101},
                      ]}/>
        <ProgressCircleCard isLoading={false}
                            title="Open BVs"
                            subTitle="Need attention"
                            hasLink={false}
                            hasList={false}
                            listItems={[
                                "WEX-23456",
                                "WEX-82650",
                                "HEK-05021"
                            ]}
                            indicatorCount={12}
                            totalCount={127}
                            showsPercentage={false}
                            progressColor={Color.red}
                            trackColor={Color.grey8}
                            progressFontSize="36px"/>

        <ProgressCircleCard isLoading={false}
                            title="Planned work"
                            subTitle="Need attention"
                            hasLink={false}
                            hasList={false}
                            listItems={[
                                "WEX-23456",
                                "WEX-82650",
                                "HEK-05021"
                            ]}
                            indicatorCount={10}
                            totalCount={127}
                            showsPercentage={false}
                            progressColor={Color.blue}
                            trackColor={Color.grey8}
                            progressFontSize="36px"/>
    </Wrapper>
}

export default ValveSensorDummyData

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
`

const BarChartCard = styled(_BarChartCard)`
    width: 300px;
    /* margin-right: 30px; */
`

const ProgressCircleCard = styled(_ProgressCircleCard)`
    margin-left: 30px;
`

const ContainerElement = styled.div`
    width: 250px;
    height: 270px;
    border-radius: 10px;
    /* flex-grow: 1; */
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
    margin-right: 30px;
`