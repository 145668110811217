import * as React from "react";
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import { Assessment } from "@material-ui/icons";

const ViewActivityButton = ({ record }: any) => {
    return record?.userId ? (
        <Button
            color="primary"
            startIcon={<Assessment />}
            component={Link}
            to={{
                pathname: '/answerSets',
                search: stringify({
                    page: 1,
                    perPage: 25,
                    sort: 'timeStamp',
                    order: 'DESC',
                    filter: JSON.stringify({ userId: record.userId }),
                }),
            }}
        >
            view activity
        </Button>
    ) : null;
};

export default ViewActivityButton;