import React from "react"
import styled from "styled-components"
import useOnClickOutside from "../../hooks/useOnClickOutside"
import { Color } from "../../styles/color"
import UserListItem from "./UserListItem"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    logoImageName?: string
    firstName: string
    lastName: string
    logout: () => void
    setChangePasswordIsVisible: (b: boolean) => void
}

const AdminHeader: React.FC<Props> = ({logoImageName, firstName, lastName, logout, setChangePasswordIsVisible, ...props}) => {

    const [ menuIsOpen, setMenuIsOpen ] = React.useState(false)
    const menuRef = React.useRef(null)
    useOnClickOutside(menuRef, () => setMenuIsOpen(false))

    return <Wrapper {...props}>
        {logoImageName && <Logo src={require(`../../images/${logoImageName}`)}/>}
        <Name firstName={firstName} lastName={lastName} onClick={() => setMenuIsOpen(true)}/>
        { menuIsOpen && <Menu ref={menuRef}>
            <MenuItem onClick={() => {setChangePasswordIsVisible(true); setMenuIsOpen(false);}}>Change password</MenuItem>
            <MenuItem onClick={logout}>Log out</MenuItem>
        </Menu> }
    </Wrapper>
}

export default AdminHeader

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
`

const Logo = styled.img`
    height: 30px;
    object-fit: contain;
    margin-left: 30px;
`

const Name = styled(UserListItem)`
    margin-left: auto;
    margin-right: 30px;

    &:hover {
        cursor: pointer;
    }
`

const Menu = styled.div`
    position: absolute;
    top: 50px;
    right: 30px;
    width: 400px;
    background: ${Color.white};
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border: 1px solid ${Color.grey8};
    overflow: hidden;
    z-index: 10;
    max-height: 600px;
    overflow: auto;
`

const MenuItem = styled.div`

    padding: 10px;

    &:hover {
        background: ${Color.grey16};
        cursor: pointer;
    }
`