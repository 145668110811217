import { Resource } from '../types';
import list from "./List";
import show from "./Show";
import { Assessment as icon } from "@material-ui/icons";
import { ErrorCard } from '../../components/errorCard'

export const responses = (permissions?: any): Resource => ({
	name: "answerSets",
	list: permissions.find((role:string) => role === 'Admin') ? list : ErrorCard,
	show,
	icon,
	options: {
		label: "Activity",
	},
});
