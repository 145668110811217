import React from "react"
import styled from "styled-components"
import { Text } from "../atoms"
import { CardWithLoading, LinkCard, ProgressCircle as _ProgressCircle} from "../molecules"
import redCross from "../../images/cross-red.svg"
import iAuditor from "../../images/iAuditor-icon.svg"

interface Props {
    title: string
    indicatorCount: number
    totalCount: number
    showsPercentage: boolean
    progressColor: string
    trackColor: string
    progressFontSize: string
    hasList: boolean
    subTitle?: string
    listItems?: string[]
    isLoading: boolean
    error?: string
    hasLink: boolean
}

const ProgressCircleCard: React.FC<Props> = ({ isLoading, error, title, progressFontSize, indicatorCount, showsPercentage, totalCount: total, progressColor, trackColor, hasList, subTitle, listItems, hasLink, ...props }) => {

    const progress = total ? (indicatorCount / total)
                           : 0
    const progressToUse = ((progress > 0.99) && (progress < 1)) ? 0.99          // Avoid rounding the progress up to 100% as it doesn't look right with common failures listed underneath it
                                                                : progress

    return <CardWithLoading isLoading={isLoading} error={error} loadingColor={progressColor} {...props}>
        <TitleWrapper>
            <Text size="large">{title}</Text>
            {hasLink && <LinkCard icon={iAuditor} text="View report" link="https://app.safetyculture.com/analytics"/>}
        </TitleWrapper>
        <ProgressCircle fontSize={progressFontSize} progressColor={progressColor} trackColor={trackColor} progress={progressToUse} textOverride={showsPercentage ? undefined : `${indicatorCount}`}/>
        {hasList && <SubTitle>{subTitle}</SubTitle>}
        {hasList && listItems?.map(li => <ListItem>
            <Cross src={redCross}/>
            <Text size="small" opacity="moderate">{li}</Text>
        </ListItem>)}
        {hasList && !listItems?.length && <Text opacity="weak" size="small">None</Text>}
    </CardWithLoading>
}

export default ProgressCircleCard

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
`

const SubTitle = styled(Text)`
    margin-top: 20px;
    margin-bottom: 10px;
`

const ListItem = styled.div`
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
`

const ProgressCircle = styled(_ProgressCircle)`
    width: 150px;
    margin: 0 auto;
`

const Cross = styled.img`
    width: 15px;
    height: 15px;
    margin-right: 10px;
`