// import React from 'react'
import styled from "styled-components"
import { Color } from '../../styles/color'

export type Size = "extra large"
                 | "large"
                 | "normal+"
                 | "normal"
                 | "small"
                 | "extra small"

export type Weight = "bold"
                   | "medium"
                   | "regular"
                   | "light"

export type Opacity = "full"
                    | "strong"
                    | "moderate"
                    | "weak"

const cssForSize = (size: Size) => (size === "extra large") ? "24px"
                                       : (size === "large") ? "22px"
                                     : (size === "normal+") ? "18px"
                                      : (size === "normal") ? "16px"
                                       : (size === "small") ? "14px"
                                        /* "extra small" */ : "12px"

const cssForWeight = (weight: Weight) => (weight === "bold") ? 700
                                     : (weight === "medium") ? 500
                                    : (weight === "regular") ? 400
                                               /* "light" */ : 300

const alphaForOpacity = (opacity: Opacity) => (opacity === "full") ? "FF"
                                          : (opacity === "strong") ? "B3"
                                        : (opacity === "moderate") ? "80"
                                                      /* "weak" */ : "4D"
                                      

interface Props {
    size?: Size,
    weight?: Weight,
    color?: string,
    opacity?: Opacity
}

const Text = styled.p<Props>`
    font-size: ${({size}) => cssForSize(size ?? "normal")};
    font-weight: ${({weight}) => cssForWeight(weight ?? "regular")};
    color: ${({color, opacity}) => (color ?? Color.black) + alphaForOpacity(opacity ?? "full")};
    line-height: 1.2em;
`

export default Text