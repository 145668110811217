import React from "react"
import { HTMLAttributes } from "react"
import styled from "styled-components"
import tick from "../../images/tick-green.svg"
import exclamation from "../../images/exclamation-mark-red.svg"
import { TrainingItem } from "../../types"
import { Color } from "../../styles/color"
import { Text } from "../atoms"
import { DateTime } from "luxon"

interface Props extends HTMLAttributes<HTMLDivElement> {
    items: TrainingItem[]
}

const TrainingDetails: React.FC<Props> = ({items, ...props}) => {

    return <Wrapper {...props}>
        <Border/>
        {items.map(item => {
            
            const lastSubmittedText = item.lastSubmitted ? ": " + DateTime.fromISO(item.lastSubmitted).toFormat("d MMM yyyy")
                                                         : ""
            const statusText = (item.isSuccess === false) ? "Attempted" + lastSubmittedText
                                         : item.isSuccess ? "Passed" + lastSubmittedText
                                                          : "Not started"
            const statusColor = (item.isDue && item.isSuccess) ? Color.red2
                                  : (item.isSuccess === false) ? Color.red2
                                              : item.isSuccess ? Color.grey2
                                                               : Color.grey4

            return <Row>
                <Icon src={item.isDue ? exclamation : tick}/>
                <TrainingName>{item.displayName}</TrainingName>
                <Text color={statusColor}>{statusText}</Text>{}
            </Row>})}
    </Wrapper>
}

export default TrainingDetails

const Wrapper = styled.div`
    background: ${Color.white};
    padding: 0 20px;
    /* border-top: 1px solid ${Color.grey4}; */
    display: flex;
    flex-direction: column;
`

const Border = styled.div`
    background: ${Color.grey4};
    height: 1px;
    /* margin-left: 20px; */
    margin-bottom: 20px;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
`

const Icon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 10px;
`

const TrainingName = styled(Text)`
    width: 350px;
`