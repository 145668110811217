import { CircularProgress as _CircularProgress, TextField as _TextField, ThemeProvider } from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import { Color } from "../../styles/color"
import { theme } from "../../styles/theme"
import { PrimaryButton as _PrimaryButton, Text as _Text } from "../atoms"
import CloseIcon from '@material-ui/icons/Close'
import { UserUpload } from "../../types"

interface Props extends React.HTMLAttributes<HTMLDivElement> {

    setIsVisible: (b: boolean) => void
    isUploading: boolean
    uploadError?: string
    uploadIsSuccessful: boolean
    startUpload: (u: UserUpload) => void
    newUserId: string
    tenantId: string
    externalIdFieldName?: string
}

const CreateUserViaUsernamePassword: React.FC<Props> = ({setIsVisible, isUploading, uploadIsSuccessful, uploadError, startUpload, newUserId, tenantId, externalIdFieldName, ...props}) => {

    const [ firstName, setFirstName ] = React.useState("")
    const [ lastName, setLastName ] = React.useState("")
    const [ email, setEmail ] = React.useState("")
    const [ password, setPassword ] = React.useState("")
    const [ confirmPassword, setConfirmPassword ] = React.useState("")
    const [ passwordIsValid, setPasswordIsValid ] = React.useState(false)
    const [ emailIsValid, setEmailIsValid ] = React.useState(false)

    const [ emailIsFocused, setEmailIsFocused ] = React.useState(false)
    const [ emailHasBeenFocused, setEmailHasBeenFocused ] = React.useState(false)
    const [ passwordIsFocused, setPasswordIsFocused ] = React.useState(false)
    const [ passwordHasBeenFocused, setPasswordHasBeenFocused ] = React.useState(false)
    const [ confirmPasswordIsFocused, setConfirmPasswordIsFocused ] = React.useState(false)
    const [ confirmPasswordHasBeenFocused, setConfirmPasswordHasBeenFocused ] = React.useState(false)

    const [ firstNameIsFocused, setFirstNameIsFocused ] = React.useState(false)
    const [ firstNameHasBeenFocused, setFirstNameHasBeenFocused ] = React.useState(false)
    const [ lastNameHasBeenFocused, setLastNameHasBeenFocused ] = React.useState(false)
    const [ lastNameIsFocused, setLastNameIsFocused ] = React.useState(false)
    const [ externalId, setExternalId ] = React.useState<string | undefined>(undefined)

    const buttonIsEnabled = (firstName.length && lastName.length && email.length && password.length && confirmPassword.length && password === confirmPassword && emailIsValid && passwordIsValid) ? true : false

    return <Overlay onClick={() => setIsVisible(false)} {...props}>
        <ThemeProvider theme={theme}>
                { uploadIsSuccessful ? <SuccessFullWrapper onClick={e => e.stopPropagation()} height={externalIdFieldName ? "550px" : "450px"}>
                    <SuccessText>Created successfully</SuccessText>
                    <PrimaryButton isEnabled={true} onClick={() => setIsVisible(false)}>Done</PrimaryButton>
                </SuccessFullWrapper>
                
                :

                <Wrapper onClick={e => e.stopPropagation()} height={externalIdFieldName ? "650px" : "620px"}>
                    <CloseButton onClick={() => setIsVisible(false)}/>
                    <Title size="large">Add user</Title>
                    <TextField label="First name" margin="normal" required value={firstName} onChange={e => setFirstName(e.target.value)} onFocus={() => {setFirstNameIsFocused(true); setFirstNameHasBeenFocused(true);}} onBlur={() => setFirstNameIsFocused(false)}/>
                    <ErrorLabel color={Color.red} size="small" isVisible={(firstName.length === 0) && !firstNameIsFocused && firstNameHasBeenFocused}>Please enter a first name</ErrorLabel>
                    <TextField label="Last name" margin="normal" required value={lastName} onChange={e => setLastName(e.target.value)} onFocus={() => {setLastNameIsFocused(true); setLastNameHasBeenFocused(true); }} onBlur={() => setLastNameIsFocused(false)}/>
                    <ErrorLabel color={Color.red} size="small" isVisible={(lastName.length === 0) && !lastNameIsFocused && lastNameHasBeenFocused}>Please enter a last name</ErrorLabel>
                    {externalIdFieldName && <TextField label={externalIdFieldName} margin="normal" required value={externalId} onChange={e => setExternalId(e.target.value)}/>}

                    <TextField label="Email" type="email" autoComplete="off" margin="normal" required value={email} onChange={e => {setEmail(e.target.value); setEmailIsValid(isValidEmail(e.target.value))}} onFocus={() => {setEmailIsFocused(true); setEmailHasBeenFocused(true);}} onBlur={() => setEmailIsFocused(false)}/>
                    <ErrorLabel color={Color.red} size="small" isVisible={(email.length === 0) && !emailIsFocused && emailHasBeenFocused}>Please enter an email</ErrorLabel>
                    <ErrorLabel color={Color.red} size="small" isVisible={(email.length > 0) && !emailIsFocused && !emailIsValid}>The email you entered is not valid</ErrorLabel>

                    <TextField label="Password" type="password" autoComplete="new-password" margin="normal" required value={password} onChange={e => {setPassword(e.target.value); setPasswordIsValid(isValidPassword(e.target.value))}} onFocus={() => {setPasswordIsFocused(true); setPasswordHasBeenFocused(true);}} onBlur={() => setPasswordIsFocused(false)}/>
                    <ErrorLabel color={Color.red} size="small" isVisible={(password.length === 0) && !passwordIsFocused && passwordHasBeenFocused}>Please enter a password</ErrorLabel>
                    <ErrorLabel color={Color.red} size="small" isVisible={(password.length > 0) && !passwordIsFocused && !passwordIsValid}>The password you entered is too weak</ErrorLabel>
                    <TextField label="Confirm Password" type="password" margin="normal" required value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} onFocus={() => {setConfirmPasswordIsFocused(true); setConfirmPasswordHasBeenFocused(true);}} onBlur={() => setConfirmPasswordIsFocused(false)}/>
                    <ErrorLabel color={Color.red} size="small" isVisible={(confirmPassword.length === 0) && !confirmPasswordIsFocused && confirmPasswordHasBeenFocused}>Please confirm password</ErrorLabel>
                    <ErrorLabel color={Color.red} size="small" isVisible={confirmPassword.length>0 && password.length>0 && (password !== confirmPassword) && !passwordIsFocused && !confirmPasswordIsFocused}>Password not matched</ErrorLabel>
                    
                    <PrimaryButton isEnabled={buttonIsEnabled} onClick={() => buttonIsEnabled && !isUploading && startUpload({firstName, lastName, email: email, password: password, tenantId, userId: newUserId, externalId})}>
                        { isUploading ? <CircularProgress style={{color: Color.black}} size="30px"/> : "Create"}
                    </PrimaryButton>
                    <UrlErrorLabel color={Color.red} size="small" isVisible={uploadError?.length ? true : false}>{uploadError}</UrlErrorLabel>
                </Wrapper>}
        </ThemeProvider>
    </Overlay>
}

export default CreateUserViaUsernamePassword

function isValidPassword(password: string) {
    const minLength = 8;
    const hasLetter = /[a-zA-Z]/.test(password);
    // const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasSpecialCharacter = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?`~]/.test(password);

    return password.length >= minLength && hasLetter && hasSpecialCharacter;
}

function isValidEmail(email: string) {
    // Regular expression for validating an email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

const Overlay = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: ${Color.translucentBlack};
    z-index: 20;
`

const SuccessFullWrapper = styled.div<{height: string}>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: ${Color.white};
    width: 300px;
    height: ${props => props.height};
    border-radius: 10px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
    margin: auto;
    margin-top: 150px;
    padding: 40px;
`

const Wrapper = styled.div<{height: string}>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: ${Color.white};
    width: 400px;
    height: ${props => props.height};
    border-radius: 10px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
    margin: auto;
    margin-top: 20px;
    padding: 40px;
`

const Title = styled(_Text)`
    margin: 0 auto 20px auto;
    text-align: center;
    align-self: center;
`

const TextField = styled(_TextField)`
    /* width: 200px; */
`

const CloseButton = styled(CloseIcon)`
    position: absolute;
    right: 10px;
    top: 10px;

    &:hover {
        cursor: pointer;
    }
`

const ErrorLabel = styled(_Text)<{isVisible: boolean}>`
    visibility: ${props => props.isVisible ? "visible" : "hidden"};
    margin-top: 4px;
`

const UrlErrorLabel = styled(ErrorLabel)`
    text-align: center;
`

const PrimaryButton = styled(_PrimaryButton)`
    margin-top: 20px;
    align-self: center;
`

const CircularProgress = styled(_CircularProgress)`
    margin-top: 5px;
`



const SuccessText = styled(_Text)`
    margin-top: 200px;
    text-align: center;
`