import * as React from "react";
import { Datagrid, List, TextField, Pagination, Button } from "react-admin";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import { useListContext } from "react-admin";
import { TopToolbar } from "react-admin";
import { ExportButton } from "react-admin";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
	isCheckedIn: boolean
}

const UserList: React.FC<Props> = (props) => {

	const infoText = props.isCheckedIn ? "The following people are currently checked into site via the Fit for Work app:"
									   : "The following people have recently reported that they have safely evacuated the site but have not checked in again:"

	const emptyText = props.isCheckedIn ? "No-one is currently checked in."
										: "No-one has evacuated recently and not returned."

	return <>
		<Centered>{infoText}</Centered>
		<List {...props} empty={<Centered>{emptyText}</Centered>}
						 bulkActionButtons={false}
						 perPage={25}
						 actions={<ListActions/>}
						 pagination={<Pagination rowsPerPageOptions={[10, 25, 50]} {...props}/>}
						 sort={{ field: 'lastName', order: 'DESC' }}>
			<Datagrid>
				<TextField source="firstName" label="First Name" />
				<TextField source="lastName" label="Last Name" />
				<TextField source="phoneNumbers[0]" label="Number" sortable={false}/>
			</Datagrid>
		</List>
	</>
}

export default UserList;

const Centered = styled.div`
	margin: 50px auto 0 auto;
	text-align: center;
`

const ListActions: React.FC = () => {
    
    const { resource } = useListContext();

	const history = useHistory()

    return (
        <TopToolbar>
			<Button  label="Alerts" onClick={() => history.push("/alerts")}><NotificationsNoneOutlinedIcon/></Button>
            <ExportButton resource={resource} />
        </TopToolbar>
    );
};