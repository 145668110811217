import React from "react"
import styled from "styled-components"
import { Color } from "../../styles/color"
import { PrimaryButton, SecondaryButton, Text } from "../atoms"
import CloseIcon from '@material-ui/icons/Close'
import { CircularProgress } from "@material-ui/core"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    userName: string
    onConfirmClicked: () => void
    setIsVisible: (b: boolean) => void
    isLoading: boolean
    deleteIsSuccessful: boolean
    deleteError?: string
}

const DeleteUser: React.FC<Props> = ({userName, onConfirmClicked, setIsVisible, isLoading, deleteIsSuccessful, deleteError, ...props}) => {

    return <Overlay onClick={() => setIsVisible(false)} {...props}>
        <Wrapper onClick={e => e.stopPropagation()}>
            { isLoading ? <ProgressContainer><CircularProgress style={{color: Color.red}}/></ProgressContainer>
            : deleteIsSuccessful ? <ConfirmationContainer>
                <DoneText>User deleted</DoneText>
                <PrimaryButton isEnabled={true} onClick={() => setIsVisible(false)}>Done</PrimaryButton>
            </ConfirmationContainer>
            : <>
                <CloseButton onClick={() => setIsVisible(false)}/>
                <Text>{`Are you sure you want to delete ${userName}?`}</Text>
                <ButtonContainer>
                    <SecondaryButton onClick={() => setIsVisible(false)}>No</SecondaryButton>
                    <ConfirmButton isEnabled={true} onClick={onConfirmClicked}>Yes</ConfirmButton>
                </ButtonContainer>
                <ErrorLabel color={Color.red} isVisible={deleteError ? true : false}>{deleteError}</ErrorLabel>
            </>}
        </Wrapper>
    </Overlay>
}

export default DeleteUser

const Overlay = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: ${Color.translucentBlack};
    z-index: 20;
`

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: ${Color.white};
    width: 300px;
    height: 150px;
    border-radius: 10px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
    margin: auto;
    margin-top: 300px;
    padding: 40px;
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 30px;
`

const ConfirmationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
`

const ConfirmButton = styled(PrimaryButton)`
    background-color: ${Color.red};
    margin-left: 30px;
    color: ${Color.white};
`

const DoneText = styled(Text)`
    margin-bottom: 20px;
    text-align: center;
`

const CloseButton = styled(CloseIcon)`
    position: absolute;
    right: 10px;
    top: 10px;

    &:hover {
        cursor: pointer;
    }
`

const ErrorLabel = styled(Text)<{isVisible: boolean}>`
    visibility: ${props => props.isVisible ? "visible" : "hidden"};
    margin-top: 4px;
    text-align: center;
`

const ProgressContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`