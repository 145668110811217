import React, { useState, useEffect } from "react";
import { useDataProvider } from 'react-admin';

const FilterByUserInput = ({ source, filterValues, ...rest }: any) => {
    const [result, setResult] = useState<any>();
    const dataProvider = useDataProvider();

    const userId = filterValues?.[source];

    useEffect(() => {
        if (userId) {
            dataProvider.getOne("users", { id: userId })
                .then(({ data }: any) => setResult(data))
                .catch((e: any) => console.error("Error fetching user... ", e))
        }
    }, [dataProvider, setResult, userId]);

    if (!result) {
        return <span>loading user...</span>;
    }

    return (
        <div>
            <span>Viewing responses for </span>
            <strong>{result.firstName} {result.lastName}</strong>
        </div>
    )

}
export default FilterByUserInput;
