import styled from "styled-components"
import { Color } from "../../styles/color"

const Card = styled.div`
    background: ${Color.white};
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 25px;
    overflow: hidden;
`

export default Card