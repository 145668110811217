import React, { FC } from 'react'
import { AnswerRecord, QuestionsAnswered } from "./types";
import { Show, SimpleShowLayout, useGetOne } from 'react-admin';
import { getThemeConfig } from '../../styles/theme';
import moment from 'moment';
import styled from "styled-components";
import AnswersTable from '../../components/fields/AnswersTable/index';
import Map from "../../components/fields/Map";
import { CellWithSignal, Signal } from '../../components/customGrid/CellWithSignal'
import { CustomTopToolbar } from '../../components/cutomTopToolbar'

interface AnswerDetailProps {
	record: {
		userName?: string,
		timeStamp?: Date
	}
}
const AnswerDetailTitle: FC<AnswerDetailProps> = ({ record }) => {
	return <span>{record.userName || 'n/a'} - {record.timeStamp ? moment(record.timeStamp).format('HH:mm DD MMM yyyy') : ''}</span>;
};


const ShowResponse = (props: any) => (
	<Show actions={<CustomTopToolbar basePath={props.basePath} />} title={<AnswerDetailTitle record={props.record} />} {...props}>
		<SimpleShowLayout>
			<RecordView {...props} />
		</SimpleShowLayout>
	</Show>
)

interface Props {
	resource: string;
	id: string;
}

interface Record {
	data: AnswerRecord;
	loaded: boolean;
}

const RecordView: FC<Props> = ({ resource, id }) => {
	const { data, loaded } = useGetOne(resource, id) as Record;
	if (!loaded) {
		return <span>Loading</span>;
	}
	return (
		<div>
			{data.location?.longitude && (
				<Map
					latitude={data.location.latitude}
					longitude={data.location.longitude}
					loadingElement={<ContainerElement />}
					containerElement={<ContainerElement />}
					mapElement={<ContainerElement />}
					googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC_1Yl3_XRRLfHLewTmKBXe1JA-A-n96bg&v=3.exp&libraries=geometry,drawing,places"
				/>
			)}
			{!data.location?.longitude && <div>Location not uploaded</div>}
			{ headerForRecord(data) }

			<AnswersTable columns={columns} data={data.questionsAnswered} />
		</div>
	)
}

const headerForRecord = (data: AnswerRecord) => {

	const extraSucceeded = data.extraFaceResults?.some(fr => fr.faceIsMatchConfidence > 80)
	const extraFailed = (!extraSucceeded && data.extraFaceResults?.length)
	const initialSucceeded = ((data?.faceResult?.faceIsMatchConfidence ?? 0) > 80)
	const initialFailed = (!initialSucceeded && data?.faceResult)

	return extraSucceeded ? <HeaderWithSignal text="Identified after questions" value="Green"/>
	   : initialSucceeded ? <HeaderWithSignal text="Identified before questions" value="Green"/>
	   		: extraFailed ? <HeaderWithSignal text="Not identified after questions" value="Red"/>
	   	  : initialFailed ? <HeaderWithSignal text="Not identified before questions" value="Red"/>
	   					  : undefined
}

const ContainerElement = styled.div`
    width: 100%;
    height: 400px;
    margin-bottom: 10px;
`;

interface SignalProps {
	value?: Signal,
	text?: string
}

const HeaderWithSignal: FC<SignalProps> = ({ text, value }) => {

	return <HeaderWithSignalWrapper>
		<CellWithSignal text={text} value={value}/>
	</HeaderWithSignalWrapper>
}

const themeConfig = getThemeConfig()

const HeaderWithSignalWrapper = styled.div`
	margin: 20px auto;
	text-align: center;
	color: ${props => themeConfig.color.text};
`

const columns = [
	{ id: 'questionText', key: 'questionText', label: 'Question', minWidth: 100 },
	{
		id: 'optionChosen',
		key: '_root',
		label: 'Answer',
		minWidth: 100,
		format: (answer: QuestionsAnswered) => {

			const isFreeText = (answer.questionType === "Free text")
							|| ((answer.questionType === "Optional free text") && answer.optionChosen.triggersFreeText)

			return isFreeText ? answer.freeText
						   	  : answer.optionChosen && answer.optionChosen.optionText
		}
	},
	{
		id: 'correctAnswer',
		key: '_root',
		label: 'Result',
		minWidth: 100,
		format: (answer: QuestionsAnswered) => {

			const isFreeText = (answer.questionType === "Free text")
							|| ((answer.questionType === "Optional free text") && answer.optionChosen.triggersFreeText)

			const isCorrectOption = (answer.optionChosen && answer.optionChosen.isCorrect)

			const optionValue = isFreeText ? "Amber"
						 : isCorrectOption ? "Green"
										   : "Red"

			const optionText = isFreeText ? "Report"
						: isCorrectOption ? "Correct"
										  : "Incorrect"

			return <CellWithSignal value={optionValue} text={optionText}/>
		}
	},
	{
		id: 'faceResult',
		key: 'faceResult',
		label: 'ID Verification',
		minWidth: 100,
		format: (faceResult: any) => {

			const idValue = (faceResult && faceResult.faceIsMatchConfidence > 80) ? "Green" : "Red"
			const idText = (faceResult && faceResult.faceIsMatchConfidence > 80) ? "Verified" : "Not Verified"

			return <CellWithSignal value={idValue} text={idText} />
		}
	}
];


export default ShowResponse;
