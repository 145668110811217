import * as React from "react";
import {
    required,
    SimpleForm as _SimpleForm,
    useRedirect,
    useRefresh,
    useNotify,
    TextInput as _TextInput,
    Create,
} from "react-admin";
import styled from "styled-components";
import PhoneNumberInput from '../../components/inputs/PhoneNumberInput';
import libPhoneNumber from "google-libphonenumber";
import { getThemeConfig } from '../../styles/theme';

// TODO: Import phone number lib custom component
const UserCreate = (props: any) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = () => {
        notify('Success!')
        redirect('/users');
        refresh();
    };
    return (
    <Create onSuccess={onSuccess} {...props} >
        <SimpleForm>
            <TextInput
                source="firstName"
                label="First Name"
                validate={[required()]}
            />
            <TextInput
                source="lastName"
                label="Last Name"
                validate={[required()]}
            />
            <PhoneNumberInput
                source="phoneNumber"
                label="Phone Number"
                validate={validatePhoneNumber}
            />
        </SimpleForm>
    </Create>
)};

export default UserCreate;

const themeConfig = getThemeConfig()

const validatePhoneNumber = (phoneNumberString: string) => {

    const withPlus = "+" + phoneNumberString
    let isValid = false

    try {
        const phoneUtil = libPhoneNumber.PhoneNumberUtil.getInstance()
        const phoneNumber = phoneUtil.parse(withPlus, "GB")
        isValid = phoneUtil.isValidNumber(phoneNumber)
    }
    catch (e) {
        //console.log(e)
    }

    return isValid ? undefined : "Phone number is not valid"
}

// Example: override components with styled
const TextInput = styled(_TextInput)`
    padding: 5px;
    box-sizing: border-box;
    width: 400px;
`;

const SimpleForm = styled(_SimpleForm)`
    button {
        background-color: ${themeConfig.color.main};
        color:  ${themeConfig.color.actionText};
        &:hover{
            background-color: ${themeConfig.color.main};
        }
    }
`

