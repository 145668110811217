import React from 'react'
import { Warning, DirectionsRun } from "@material-ui/icons"
import List from "./List"
import { ErrorCard } from '../../components/errorCard'

export const fireEvac = (permissions: any) => ({
    name: "fire-evacuation",
    list: permissions.find((role:string) => role === 'Admin') ?  (props: any) => <List isCheckedIn={true} {...props}/> : ErrorCard,
    icon: Warning,
    options: {
        label: "Fire Evacuation",
    }
})

export const fireCheckIn = (permissions: any) => ({
    name: "recently-evacuated",
    list: permissions.find((role:string) => role === 'Admin') ? (props: any) => <List isCheckedIn={false} {...props}/> : ErrorCard,
    icon: DirectionsRun,
    options: {
        label: "Recently Evacuated",
    }
})