import React from "react"
import { useHistory } from "react-router"
import { userHasNewBranding, userHasNewDashboard } from "../../providers/auth.service"

const Landing: React.FC = () => {

    const history = useHistory()

    if (userHasNewDashboard()) {
        history.push("/dashboard")
    }
    else if (userHasNewBranding()) {
        history.push("/activity")
    }
    else {
        history.replace("/answerSets")
    }

    return <div></div>
}

export default Landing