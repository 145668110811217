import React, { FC, useState, useCallback } from "react";
import { Edit, SimpleForm, TextInput, Toolbar, SaveButton, useNotify, useRefresh } from 'react-admin';
import { TextField, Typography, Button, InputAdornment, IconButton } from "@material-ui/core";
import { getCurrentUsername } from "../../providers/auth.service";
import { Edit as EditIcon } from "@material-ui/icons";
import Modal from "../../components/modal";
import { login } from "../../providers/auth.service";

export default (props: any) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [passwordUnlocked, setPasswordUnlocked] = useState(false);
    const onSuccess = () => {
        notify(`Your password has been saved`)
        refresh();
    };

    return (
        <Edit {...props} undoable={false} onSuccess={onSuccess}>
            <SimpleForm
                redirect="edit"
                toolbar={<EditToolbar saveVisible={passwordUnlocked} />}
                validate={validateForm}
            >
                <div style={{width: 400}}>
                    <TextField
                        label="your email"
                        value={getCurrentUsername()}
                        variant="outlined"
                        margin="dense"
                        disabled={true}
                        fullWidth={true}
                    />
                    {!passwordUnlocked && <PasswordEditor onUnlock={() => setPasswordUnlocked(true)} />}
                    {passwordUnlocked && (
                        <div style={{marginTop: 20}}>
                            <TextInput
                                source="password"
                                type="password"
                                variant="outlined"
                                label="New password"
                                fullWidth
                                required
                            />
                            <TextInput
                                source="passwordConfirm"
                                label="Repeat password"
                                type="password"
                                variant="outlined"
                                fullWidth
                                required
                            />
                        </div>
                    )}
                </div>
            </SimpleForm>
        </Edit>
    );
}

const validateForm = (values: any) => {
    const errors = {} as any;
    if (!values.password && !values.passwordConfirm) {
        return errors;
    }
    if (values.password !== values.passwordConfirm) {
        errors.passwordConfirm = ["Passwords must match"];
    }
    return errors;
}


interface PasswordEditorProps {
    onUnlock: VoidFunction;
}

const PasswordEditor: FC<PasswordEditorProps> = ({ onUnlock }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [password, setPassword] = useState("");

    const onSubmit = useCallback((e: any) => {
        e.preventDefault();
        login(getCurrentUsername(), password)
            .then(onUnlock)
            .catch(() => window.alert(`Invalid password. Please try again`));
    }, [password, onUnlock])

    return (
        <>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <form onSubmit={onSubmit}>
                    <Typography variant="subtitle1">
                        Please confirm your current password
                    </Typography>
                    <br/>
                    <TextField
                        label="your password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        variant="outlined"
                        type="password"
                        margin="dense"
                        fullWidth={true}
                    />
                    <br/>
                    <br/>
                    <Button variant="contained" color="secondary" onClick={onSubmit} disabled={password.length === 0}>
                        continue
                    </Button>
                </form>
            </Modal>
            <TextField
                style={{ marginTop: 30 }}
                label="your password"
                value={"randomstring"}
                variant="outlined"
                type="password"
                margin="dense"
                disabled={true}
                fullWidth={true}
                onClick={() => setModalOpen(true)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setModalOpen(true)}
                                edge="end"
                            >
                                <EditIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </>
    )
}

const EditToolbar = (props: any) => (
    <Toolbar {...props}>
        <SaveButton
            label="Save"
            disabled={props.invalid || props.saving || props.pristine}
        />
    </Toolbar>
);
