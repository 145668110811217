import { Authenticated } from 'ra-core'
import React, { FC } from 'react'
import styled from 'styled-components'
import { Menu as _Menu } from '../../components/layouts'
import config from '../../config'
import { useWebGet } from '../../hooks/useWebGet'
import { useWebPut } from '../../hooks/useWebPut'
import { getFirstName, getLastName, getTenants, logout, usesWettonLogin } from '../../providers/auth.service'
import { DateTime } from 'luxon'
// import { getThemeConfig } from '../../styles/theme'
import { SiteHeader as _SiteHeader, Pager, CreateUser, DeleteUser, ChangePassword } from '../../components/organisms'
import { Tenant, UserResponse } from '../../types'
import { Color } from '../../styles/color'
import { Text, TableHeaderRow, TableRow, PrimaryButton } from '../../components/atoms'
import { AdminHeader, HeaderCell, TextWithSignal } from '../../components/molecules'
import { menuOptionsForConfig } from '../../components/layouts/menu'
import { useHistory, useLocation } from 'react-router-dom'
import { CircularProgress, IconButton } from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid';
import { useWebDelete } from '../../hooks/useWebDelete'
import binIcon from "../../images/dustbin-red.svg"
import queryString from 'query-string'
import sortdown from "../../images/sort_down_icon.svg"
import sortup from "../../images/sort_up_icon.svg"
// import useWindowSize from '../../hooks/useWindowSize'
import TextField from '@material-ui/core/TextField'
import CancelIcon from '@material-ui/icons/Cancel'
import SearchIcon from '@material-ui/icons/Search'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import CreateUserViaUsernamePassword from '../../components/organisms/CreateUserViaUsernamePassword'

const Users: FC = () => {

    const history = useHistory()
    const location = useLocation()
    const tenants = getTenants()

    if (!tenants?.length) {
        history.push("/login")
    }

    const { tenantId } = queryString.parse(location.search)

    const tenant = tenants?.[0]         // TODO: Proper check for undefined
    const siteConfig = tenant?.siteConfig   // TODO: Type narrowing to avoid needing this check for undefined
    const subTenants = tenant?.subTenants?.length ? tenant.subTenants : undefined
    const tenantsToUse = (siteConfig?.managesSubTenants && subTenants) ? [tenant, ...subTenants] : [tenant]

    const selectedTenant = tenantId ? (tenantsToUse.filter(t => t?.tenantId === tenantId)[0] ?? tenantsToUse[0])
                                    : tenantsToUse[0]

    const isMultiTenantedProject = (selectedTenant?.authorizedThirdPartyTenants?.length || 0)>0
    // const [ selectedTenant, setSelectedTenant ] = React.useState(tenantsToUse?.[0])

    const [ addUserClicked, setAddUserClicked ] = React.useState(false)
    const [ changePasswordIsVisible, setChangePasswordIsVisible ] = React.useState(false)
    const [ createWithUsernamePasswordIsVisible, setCreateWithUsernamePasswordIsVisible ] = React.useState(false)
    const [ createIsVisible, setCreateIsVisible ] = React.useState(false)
    const [ deleteIsVisible, setDeleteIsVisible ] = React.useState(false)
    const [ deleteUserId, setDeleteUserId ] = React.useState("")
    const [ deleteUserName, setDeleteUserName ] = React.useState("")
    const [ currentPage, setCurrentPage ] = React.useState(1)
    const [ newUserId, setNewUserId ] = React.useState(uuidv4())
    const pageSize = 10
    const [ asc, setAsc ] = React.useState(true)
    const [ sort, setSort ] = React.useState('lastName')
    const [ filterText, setFilterText ] = React.useState('')
    const {
        isLoading,
        errorCode,
        data,
        refetch,
    } = useWebGet<UserResponse>(`${config.API_URL}/tenants/${selectedTenant?.tenantId}/users?pagesize=${pageSize}&page=${currentPage}&sort=${sort + "," + (asc ? 'asc' : 'desc')}&filterText=${filterText}`,
                                !!selectedTenant)

    console.log("UserData = ",data);

    const {
        isLoading: isUploading,
        isSuccess: uploadIsSuccessful,
        errorCode: uploadErrorCode,
        start: startUpload,
        reset: resetUpload,
    } = useWebPut(isSuccess => isSuccess && refetch())

    const {
        isLoading: isDeleting,
        isSuccess: deleteIsSuccessful,
        errorCode: deleteErrorCode,
        start: startDelete,
        reset: resetDelete,
    } = useWebDelete(isSuccess => isSuccess && refetch())

    const addUserRef = React.useRef(null)
    useOnClickOutside(addUserRef, (_) => { setAddUserClicked(false) })

    const Icon = styled.img`
    width: 25px;
    height: 25px;
    vertical-align: -7px;
    `
    function onSortClick(sortby:string) {
        if(sortby === sort){
            setAsc(!asc)
        } else {
            setAsc(true)
        }
        
        setSort(sortby )
    }

    function setSearchQuery(filterText:string) {
        setFilterText(filterText)
    }    

    return <Authenticated>
        { siteConfig && tenantsToUse && selectedTenant ? <Wrapper>
            { changePasswordIsVisible && <ChangePassword setIsVisible={setChangePasswordIsVisible}/> }
            { createWithUsernamePasswordIsVisible && <CreateUserViaUsernamePassword setIsVisible={setCreateWithUsernamePasswordIsVisible}
                                             isUploading={isUploading}
                                             uploadIsSuccessful={uploadIsSuccessful}
                                             uploadError={uploadErrorCode === 409 ? "That user already exists"
                                                                : uploadErrorCode ? "Something went wrong, please try again"
                                                                                  : undefined}
                                             startUpload={u => startUpload(urlForUser(newUserId), u)}
                                             newUserId={newUserId}
                                             tenantId={selectedTenant?.tenantId}
                                             externalIdFieldName={selectedTenant?.externalIdFieldName}/>}
            { createIsVisible && <CreateUser setIsVisible={setCreateIsVisible}
                                             isUploading={isUploading}
                                             uploadIsSuccessful={uploadIsSuccessful}
                                             uploadError={uploadErrorCode === 409 ? "That user already exists"
                                                                : uploadErrorCode ? "Something went wrong, please try again"
                                                                                  : undefined}
                                             startUpload={u => startUpload(urlForUser(newUserId), u)}
                                             newUserId={newUserId}
                                             tenantId={selectedTenant?.tenantId}
                                             externalIdFieldName={selectedTenant?.externalIdFieldName}/>}
            { deleteIsVisible && <DeleteUser userName={deleteUserName}
                                             onConfirmClicked={() => startDelete(urlForTenantUser(selectedTenant.tenantId, deleteUserId))}
                                             setIsVisible={setDeleteIsVisible}
                                             isLoading={isDeleting}
                                             deleteIsSuccessful={deleteIsSuccessful}
                                             deleteError={deleteErrorCode ? "Something went wrong, please try again" : undefined}/> }
            <Menu options={menuOptionsForConfig(siteConfig)} selectedOption="Manage users"/>
            <Content>
                <AdminHeader logoImageName={tenant?.logoImageName} firstName={getFirstName() ?? ""} lastName={getLastName() ?? ""} logout={() => { logout(); history.push(usesWettonLogin() ? "/wetton" : "/login")}} setChangePasswordIsVisible={setChangePasswordIsVisible}/>
                <SiteHeader tenants={tenantsToUse as Tenant[]}
                            selectedTenant={selectedTenant}
                            changeTenantText={siteConfig.changeSubTenantText ?? "Change dashboard"}
                            setSelectedTenant={t => history.push(location.pathname + "?tenantId=" + t.tenantId)}/>
                {isLoading && <CircularProgress color="primary"/>}
                {errorCode && <div>{`Something went wrong getting users, please refresh to try again (${errorCode})`}</div>}
                {data && <Scroller>
                    
                            <TitleSection>
                            <div style={{display: "flex", width: "100%"}} >
                                <div style={{flex: "9"}}>
                                    <Text size="large">{`All operatives for ${selectedTenant.tenantName}`}</Text>
                                </div>
                                <div style={{flex: "3", paddingLeft:"10px"}} >
                                    <TextField
                                    id="search-bar"
                                    className="text"
                                    onKeyPress={(e) => {
                                        if(e.key.toString() === 'Enter'){
                                            setSearchQuery((e.target as HTMLInputElement)['value'].toString());
                                        }
                                    
                                    }}
                                    label={ <div style={{display:"flex"}}><div style={{marginTop:"-4px"}}><SearchIcon /></div>Search</div>}
                                    variant="outlined"
                                    placeholder="Start typing..."
                                    size="small"
                                    
                                    InputProps={{
                                        endAdornment: filterText && (
                                            <IconButton
                                            style={{padding:"0px"}}
                                            onClick={(e) => {
                                                if(document.getElementById('search-bar')) {
                                                    (document.getElementById('search-bar') as HTMLInputElement).value = ""
                                                } 
                                                setSearchQuery("")
                                            }}
                                            ><CancelIcon /></IconButton>
                                        )
                                        }}
                                        >
                                    </TextField>
                                </div>
                                <div style={{flex: "1", paddingLeft:"10px"}}>
                                    <PrimaryButton isEnabled={true} onClick={(_) => {setAddUserClicked(true)}}>Add user</PrimaryButton>
                                </div>
                                {addUserClicked && <MenuContainer ref={addUserRef}>
                                    <MenuItem onClick={(_) => {resetUpload(); setNewUserId(uuidv4()); setCreateIsVisible(true); setAddUserClicked(false)}}><Text>with <b>mobile number</b></Text></MenuItem>
                                    <MenuItem onClick={(_) => {resetUpload(); setNewUserId(uuidv4()); setCreateWithUsernamePasswordIsVisible(true); setAddUserClicked(false)}}><Text>with <b>email</b> and <b>password</b></Text></MenuItem>
                                </MenuContainer>}
                            </div>
                            </TitleSection>
                    <Table>
                        <TableHeaderRow>
                            <HeaderCellWithHover onClick={() => { onSortClick("firstName") }} >First name{ sort === "firstName" && <Icon src={asc ? sortup : sortdown }/>}</HeaderCellWithHover>
                            <HeaderCellWithHover onClick={() => { onSortClick("lastName") }}>Last name{ sort === "lastName" && <Icon src={asc ? sortup : sortdown }/>}</HeaderCellWithHover>
                            <HeaderCell>Phone number/Email</HeaderCell>
                            {selectedTenant?.externalIdFieldName && <HeaderCellWithHover onClick={() => { onSortClick("externalId") }}>{selectedTenant?.externalIdFieldName}{ sort === "externalId" && <Icon src={asc ? sortup : sortdown }/>}</HeaderCellWithHover>}
                            {isMultiTenantedProject && <HeaderCellWithHover onClick={() => { onSortClick("usersOwningOrgs") }}>Organisation{ sort === "usersOwningOrgs" && <Icon src={asc ? sortup : sortdown }/>}</HeaderCellWithHover>}
                            <HeaderCellWithHover onClick={() => { onSortClick("lastSubmission") }}>User status{ sort === "lastSubmission" && <Icon src={asc ? sortup : sortdown }/>}</HeaderCellWithHover>
                            <HeaderCellWithHover onClick={() => { onSortClick("lastSubmissionDate") }}>Recent activity{ sort === "lastSubmissionDate" && <Icon src={asc ? sortup : sortdown }/>}</HeaderCellWithHover>
                            {/* <HeaderCell>Recent activity</HeaderCell> */}
                        </TableHeaderRow>
                        <Spacer/>
                        {data.users.map((u, i) => {

                            const isInvited = (u.sms?.status === 'SUCCESS' || u.emailInvite?.status === "SUCCESS")
                            const isVerified = (u.emailInvite?.status === "VERIFIED")
                            const haveSubmissions = u.lastSubmission
                            const text = haveSubmissions ? "Active"
                                              : isVerified ? "Verified"
                                              : isInvited ? "Invited"
                                                          : "Not Invited"
                            const signal = haveSubmissions ? "Green"
                                                             : isVerified ? "Grey"
                                                             : isInvited ? "Amber"
                                                                         : "Red"

                            return <>
                                <TableRow>
                                    <TableCell><Text>{u.firstName}</Text></TableCell>
                                    <TableCell><Text>{u.lastName}</Text></TableCell>
                                    <TableCell><Text>{u.phoneNumber && u.phoneNumber.length>0 ? u.phoneNumber : u.email}</Text></TableCell>
                                    {selectedTenant?.externalIdFieldName && <TableCell><Text>{u.externalId ?? "-"}</Text></TableCell>}
                                    {isMultiTenantedProject && <TableCell><Text>{u.usersOwningOrgs}</Text></TableCell>}
                                    <TableCell><TextWithSignal signal={signal}>{text}</TextWithSignal></TableCell>
                                    <TableCell><Text weight="light">{u.lastSubmissionDate ? DateTime.fromISO(u.lastSubmissionDate).toFormat("HH:mm | d MMM yyyy") : "Never"}</Text></TableCell>
                                    <TableCell><DeleteIcon src={binIcon} onClick={() => { resetDelete(); setDeleteUserId(u.userId); setDeleteUserName(u.firstName + " " + u.lastName); setDeleteIsVisible(true); }}/></TableCell>
                                </TableRow>
                                <Spacer/>
                            </>})}
                    </Table>
                    <Pager currentPage={currentPage} pageSize={pageSize} totalCount={data.totalCount} onNextClicked={() => setCurrentPage(currentPage + 1)} onPreviousClicked={() => setCurrentPage(currentPage - 1)}/>

                </Scroller>}
            </Content>
        </Wrapper> : <div></div>}
    </Authenticated>
}

export default Users

const urlForUser = (id: string) => `${config.API_URL}/users/${id}`
const urlForTenantUser = (tenantId: string, userId: string) => `${config.API_URL}/tenants/${tenantId}/users/${userId}`

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${Color.lightGrey};
`

const Content = styled.div`
    flex-shrink: 1;
    flex-grow: 1;
    height: 100vh;
    overflow-y: auto;
`

const Menu = styled(_Menu)`
    flex-shrink: 0;
`

const MenuContainer = styled.div`
    position: absolute;
    top: 253px;
    right: 40px;
    width: 250px;
    background: ${Color.white};
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border: 1px solid ${Color.grey8};
    overflow: hidden;
    z-index: 10;
    max-height: 600px;
    overflow: auto;
`

const MenuItem = styled.div`
    padding: 10px;

    &:hover {
        background: ${Color.grey16};
        cursor: pointer;
    }
`

const SiteHeader = styled(_SiteHeader)`
    min-height: 100px;
    box-sizing: border-box;
`

const Scroller = styled.div`
    overflow: auto;
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
`

const TitleSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const Table = styled.table`
    margin-top: 20px;
    width: 100%;
`

const Spacer = styled.tr`
    height: 10px;
`

const TableCell = styled.td`
    padding: 15px 0;

    :first-child {
        padding-left: 20px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    :last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
`

const DeleteIcon = styled.img`
    width: 20px;
    height: 20px;

    &:hover {
       cursor: pointer;
    }
`
const HeaderCellWithHover = styled(HeaderCell)`
    cursor: pointer !important;
`


// const TableRowWithHover = styled(TableRow)`
//     &:hover {
//         cursor: pointer;
//     }
// `
