import React from "react"
import styled from "styled-components"
import { Color } from "../../styles/color"
import { Text } from "../atoms"
import { CardWithLoading, BarChart as _BarChart, LinkCard } from "../molecules"
import { BarChartItem } from "../molecules/bar-chart"
import redCross from "../../images/cross-red.svg"
import iAuditor from "../../images/iAuditor-icon.svg"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    title: string
    chartItems: BarChartItem[]
    yAxisIsPercentage?: boolean
    subTitle: string
    hasList: boolean
    listItems: string[]
    isLoading: boolean
    error?: string
    hasLink: boolean
    barForegroundColor?: string
    barBackgroundColor?: string
}

const BarChartCard: React.FC<Props> = ({isLoading, error, title, chartItems: items, subTitle, hasList, listItems, yAxisIsPercentage, hasLink, barForegroundColor, barBackgroundColor, ...props}) => {

    return <CardWithLoading isLoading={isLoading} error={error} loadingColor={Color.green} {...props}>
        <TitleWrapper>
            <Text size="large">{title}</Text>
            { hasLink && <LinkCard icon={iAuditor} text="View report" link="https://app.safetyculture.com/analytics"/>}
        </TitleWrapper>
        <BarChart barBackgroundColor={barBackgroundColor ?? Color.blue4} barForegroundColor={barForegroundColor ?? Color.green} axisColor={Color.grey4} items={items} yAxisIsPercentage={yAxisIsPercentage}/>

        {hasList && <SubTitle>{subTitle}</SubTitle>}
        {hasList && listItems.map(li => <ListItem>
            <Cross src={redCross}/>
            <Text size="small" opacity="moderate">{li}</Text>
        </ListItem>)}
        {hasList && (listItems.length === 0) && <Text opacity="weak" size="small">None</Text>}
    </CardWithLoading>
}

export default BarChartCard

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
`

const SubTitle = styled(Text)`
    margin-top: 20px;
    margin-bottom: 10px;
`

const ListItem = styled.div`
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Cross = styled.img`
    width: 15px;
    height: 15px;
    margin-right: 10px;
`

const BarChart = styled(_BarChart)`
    max-height: 150px;
`