import React from "react"

const useOnClickOutside = (ref: React.MutableRefObject<any>, handler: (event: MouseEvent | TouchEvent) => void) => {
    
    React.useEffect(() => {

        const onClickOutside = (event: MouseEvent | TouchEvent) => {
          if (ref.current && !ref.current.contains(event.target)) {
            handler(event)
          }
        }

        document.addEventListener("mousedown", onClickOutside)
        document.addEventListener("touchstart", onClickOutside)

        return () => {
          document.removeEventListener("mousedown", onClickOutside);
          document.removeEventListener("touchstart", onClickOutside);
        }
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ref, handler]
    );
}

export default useOnClickOutside