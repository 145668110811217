import config from '../../config'
import {
	SimpleForm,
	useNotify,
    Button,
    Toolbar,
} from "react-admin";
import Axios from 'axios';
import React from "react"
import styled from 'styled-components';
import { RadioButtonGroupInput as _RadioButtonGroupInput, TextInput } from 'ra-ui-materialui';
import { required } from 'react-admin';
import SendIcon from '@material-ui/icons/Send';
// import { Toolbar } from 'react-admin';
// import { SaveButton } from 'react-admin';

interface Props {

}

const Alerts: React.FC<Props> = () => {

    const notify = useNotify()

    return <Container>
        <SimpleForm toolbar={<SendToolbar />} save={async (o: any) => await sendMessage(o, notify)} class={"MuiPaper-elevation1 MuiPaper-rounded MUIPaper-root MUICard-root"}>
            <TextInput multiline source="message" validate={[required()]}/>
            <RadioButtonGroupInput source="alertType"
                                   label="Send message to:"
                                   helperText={`Users are checked out:\n• when they report that they have safely left the site\n• at 6am daily`} //"Checked in users are users that have responded to the Check in questions since 6am but not reported that they have safely left the site."
                                   validate={[required()]} choices={[
                { id: "allUsers", name: "All users", },
                { id: "checkedInUsers", name: "Checked in users" },
                { id: "checkedOutUsers", name: "Recently evacuated users"}
            ]}/>
            
        </SimpleForm>
    </Container>
}

export default Alerts

const SendToolbar: React.FC = (props: any) => {

    const isDisabled = props.invalid
    let className = "MuiButtonBase-root MuiButton-root MuiButton-contained RaSaveButton-button-102 MuiButton-containedPrimary "

    if (isDisabled) {
        className += "Mui-disabled"
    }

    return <Toolbar {...props}>
        <Button class={className}
                onClick={props.handleSubmit}>
                    <ButtonContent>
                        <SendIcon/>
                        <ButtonLabel>Send</ButtonLabel>
                    </ButtonContent>
        </Button>
    </Toolbar>
}

const sendMessage = async (o: any, notify: any) => {

    const profile = JSON.parse(sessionStorage.getItem('profile') as string)
	const accessToken = sessionStorage.getItem('access_token')

	try {
		const response = await Axios({
			method: 'post',
			url:`${config.API_URL}/tenants/${profile['https://facecheck.ai/tenantId']}/alert`,
			headers: { Authorization: "Bearer " +  accessToken },
            data: {
                alertType: o.alertType,
                message: o.message,
            }
		})

        console.log(response)
		if (response.status === 200) {
			notify("Sent")
		}
        else {
            notify("Something went wrong, please try again", "error")
        }
	}
	catch (Error) {
		notify("Something went wrong, please try again", "error")
	}
}

const Container = styled.div`
	background: white;
	margin-top: 1em;
`

const RadioButtonGroupInput = styled(_RadioButtonGroupInput)`
    white-space: pre;
`

const ButtonContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const ButtonLabel = styled.div`
    margin-left: 10px;
    font-size: 14px;
    margin-top: 2px;
`