import { Resource } from '../types';
import list from "./List";
import create from "./Create";
import { PermIdentity as icon }  from "@material-ui/icons";
import { ErrorCard } from '../../components/errorCard'

export const users = (permissions?: any): Resource => ({
    name: "users",
    list: permissions.find((role:string) => role === 'Admin') ? list : ErrorCard,
    create,    
    icon,
    options: {
        label: "Users",
    },
});
