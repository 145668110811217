import React from 'react'
import Path from 'svg-path-generator'

interface Props extends React.HtmlHTMLAttributes<SVGSVGElement> {
    barBackgroundColor: string
    barForegroundColor: string
    axisColor: string
    items: BarChartItem[]
    yAxisIsPercentage?: boolean
}

export type BarChartItem = {
    label: string
    backgroundValue: number
    foregroundValue: number
}

const BarChart: React.FC<Props> = ({items, barForegroundColor, barBackgroundColor, axisColor, yAxisIsPercentage, ...props}) => {

    const fontSize = 17
    const barStrokeWidth = 10

    const yAxisLabelWidth = 45
    const yAxisLabelMargin = 10
    const yAxisTopMargin = 10
    const yAxisStartX = (yAxisLabelWidth + yAxisLabelMargin)
    const yAxisHeight = 200
    const xAxisLabelHeight = 30
    const xAxisLabelWidth = 30
    const xAxisLabelMarginX = 15
    const xAxisLabelMarginY = 5
    const xAxisY = (yAxisTopMargin + yAxisHeight)

    const width = (yAxisStartX + (xAxisLabelWidth * items.length) + (xAxisLabelMarginX * (items.length - 1)))
    const height = (xAxisLabelHeight + yAxisHeight + yAxisTopMargin)

    const yAxisSteps = getYAxisStep(items)
    const yAxisMaxValue = (Math.ceil(yAxisSteps.numberOfSteps) * yAxisSteps.stepHeight)

    let yAxisLabels = new Array<number>()

    for (let y = 0; y <= yAxisMaxValue; y += yAxisSteps.stepHeight) {
        yAxisLabels.push(y)
    }

    return <svg viewBox={`0 0 ${width} ${height}`} {...props}>
        
        { yAxisLabels.map(value => {
                const y = (yAxisTopMargin + (yAxisHeight * (1 - (value / yAxisMaxValue))))
                const yText = yAxisIsPercentage ? (value + "%") : value
                return <>
                    <text x={yAxisLabelWidth} y={y} dominantBaseline="central" textAnchor="end" fill={axisColor} fontSize={fontSize}>{yText}</text>
                    <line x1={yAxisStartX} y1={y} x2={width} y2={y} stroke={axisColor}/>
                </>
            }) }
        
        {items.map((item, i) => {

            const x = (yAxisStartX + ((i + 0.5) * xAxisLabelWidth) + (i * xAxisLabelMarginX))

            const backgroundHeight = ((item.backgroundValue / yAxisMaxValue) * yAxisHeight)
            const foregroundHeight = ((item.foregroundValue / yAxisMaxValue) * yAxisHeight)

            return <>
                <text x={x} y={xAxisY + xAxisLabelMarginY} dominantBaseline="hanging" textAnchor="middle" fill={axisColor} fontSize={fontSize}>{item.label}</text>
                {verticalLineWithCoordinates({midX: x, bottomY: xAxisY, topY: (xAxisY - backgroundHeight), width: barStrokeWidth, color: barBackgroundColor})}
                {verticalLineWithCoordinates({midX: x, bottomY: xAxisY, topY: (xAxisY - foregroundHeight), width: barStrokeWidth, color: barForegroundColor})}
            </>
        })}

    </svg>
}

export default BarChart

const getYAxisStep = (items: BarChartItem[]) => {

    const maxNumberOfSteps = 5
    const possibleYAxisSteps = [1,2,5,10,20,50,100]

    const maxValue = Math.max(...items.map(i => i.backgroundValue),
                              ...items.map(i => i.foregroundValue))
    
    const numberOfSteps = possibleYAxisSteps.map(s => {return { numberOfSteps: (maxValue / s), stepHeight: s}})
    const validSteps = numberOfSteps.filter(({numberOfSteps}) => numberOfSteps <= maxNumberOfSteps)

    return validSteps[0]
}

interface VerticalLineProps {
    midX: number
    bottomY: number
    topY: number
    width: number
    color: string
}

const verticalLineWithCoordinates = (props: VerticalLineProps) => {

    const { midX, bottomY, topY, width, color } = props

    if (topY === bottomY) {
        return <></>
    }

    const arcRadius = (width / 2)
    const leftX = (midX - arcRadius)
    const rightX = (midX + arcRadius)
    const bottomArcY = (topY + arcRadius)

    const path = Path().moveTo(leftX, bottomY)
                       .lineTo(leftX, bottomArcY)
                       .ellipticalArc(arcRadius, arcRadius, 0, 1, 1, rightX, bottomArcY)
                       .lineTo(rightX, bottomY)
                       .close()

    return <path d={path} fill={color}/>
}