import { Authenticated } from 'ra-core'
import React from "react"
import styled from "styled-components"
import { Color } from '../../styles/color'
import { Menu as _Menu, menuOptionsForConfig } from '../../components/layouts'
import { Text, TableHeaderRow, TableRow, TableCell, ExpandedTableCell, TertiaryButton } from '../../components/atoms'
import { Pager as _Pager, SiteHeader as _SiteHeader, TrainingDetails, ChangePassword } from '../../components/organisms'
import { getFirstName, getLastName, getTenants, logout, usesWettonLogin } from '../../providers/auth.service'
import { useWebGet } from '../../hooks/useWebGet'
import config from '../../config'
import { Tenant, TrainingPage } from '../../types'
import { CircularProgress, IconButton } from '@material-ui/core'
import { TextWithSignal, HeaderCell, AdminHeader } from '../../components/molecules'
import { useHistory, useLocation } from 'react-router-dom'
import { downloadTrainingReport } from '../../providers/training'
import queryString from 'query-string'
import sortdown from "../../images/sort_down_icon.svg"
import sortup from "../../images/sort_up_icon.svg"
import TextField from '@material-ui/core/TextField'
import CancelIcon from '@material-ui/icons/Cancel'
import SearchIcon from '@material-ui/icons/Search'

const Training: React.FC = () => {

    const history = useHistory()
    const location = useLocation()
    const tenants = getTenants()

    if (!tenants?.length) {
        history.push("/login")
    }

    const { tenantId } = queryString.parse(location.search)

    const tenant = tenants?.[0]         // TODO: Proper check for undefined
    const siteConfig = tenant?.siteConfig   // TODO: Type narrowing to avoid needing this check for undefined
    const subTenants = tenant?.subTenants?.length ? tenant.subTenants : undefined
    const tenantsToUse = (siteConfig?.managesSubTenants && subTenants) ? [tenant, ...subTenants] : [tenant]
    
    const [ changePasswordIsVisible, setChangePasswordIsVisible ] = React.useState(false)
    // const [ selectedTenant, setSelectedTenant ] = React.useState(tenantsToUse[0])
    const [ openRow, setOpenRow ] = React.useState<number | undefined>(undefined)
    const [ currentPage, setCurrentPage ] = React.useState(1)
    const [ reportIsError, setReportIsError ] = React.useState(false)
    const pageSize = 10
    const [ asc, setAsc ] = React.useState(true)
    const [ sort, setSort ] = React.useState('lastName')
    const [ filterText, setFilterText ] = React.useState('')
    const selectedTenant = tenantId ? (tenantsToUse.filter(t => t?.tenantId === tenantId)[0] ?? tenantsToUse[0])
                                    : tenantsToUse[0]

    const { isLoading, errorCode, data} = useWebGet<TrainingPage>(`${config.API_URL}/tenants/${selectedTenant?.tenantId}/report/expectations/training?pagesize=${pageSize}&page=${currentPage}&filterText=${filterText}&sort=${sort + "," + (asc ? 'asc' : 'desc')}`,
                                                                    !!selectedTenant)
    const Icon = styled.img`
    width: 25px;
    height: 25px;
    vertical-align: -7px;
`
    function setSearchQuery(filterText:string) {
        setFilterText(filterText)
    }  

    function onSortClick(sortby:string) {
        if(sortby === sort){
            setAsc(!asc)
        } else {
            setAsc(true)
        }
        
        setSort(sortby )
    }
    React.useEffect(() => {

        if (reportIsError) {
            const interval = setInterval(() => {
                setReportIsError(false)
            }, 5000)

            return () => clearInterval(interval)
        }

    }, [reportIsError, setReportIsError])

    return <Authenticated>
        { siteConfig && tenantsToUse && selectedTenant ? <Wrapper>
            { changePasswordIsVisible && <ChangePassword setIsVisible={setChangePasswordIsVisible}/> }
            <Menu options={menuOptionsForConfig(siteConfig)} selectedOption="Training records"/>
            <Content>
                <AdminHeader logoImageName={tenant?.logoImageName} firstName={getFirstName() ?? ""} lastName={getLastName() ?? ""} logout={() => { const usesWetton = usesWettonLogin(); logout(); history.push(usesWetton ? "/wetton" : "/login")}} setChangePasswordIsVisible={setChangePasswordIsVisible}/>
                <SiteHeader tenants={tenantsToUse as Tenant[]}
                            changeTenantText={siteConfig.changeSubTenantText ?? "Change dashboard"}
                            selectedTenant={selectedTenant}
                            setSelectedTenant={t => history.push(location.pathname + "?tenantId=" + t.tenantId)}/>
                {isLoading && <CircularProgress color="primary"/>}
                {errorCode && <div>{`Something went wrong getting training, please refresh to try again (${errorCode})`}</div>}
                <Scroller>
                    <TitleSection>
                        <div style={{display: "flex", width: "100%"}} >
                            <div style={{flex: "3"}}>
                                <Text size="large">Operative training records</Text>
                            </div>
                            <div style={{flex: "1", paddingLeft:"10px", "marginLeft": "auto"}} >
                                <TextField
                                    id="search-bar"
                                    className="text"
                                    onKeyPress={(e) => {
                                        if(e.key.toString() === 'Enter'){
                                            setSearchQuery((e.target as HTMLInputElement)['value'].toString());
                                        }
                                    
                                    }}
                                    label={ <div style={{display:"flex"}}><div style={{marginTop:"-4px"}}><SearchIcon /></div>Search</div>}
                                    variant="outlined"
                                    placeholder="Start typing..."
                                    size="small"
                                    
                                    InputProps={{
                                        endAdornment: filterText && (
                                            <IconButton
                                            style={{padding:"0px"}}
                                            onClick={(e) => {
                                                if(document.getElementById('search-bar')) {
                                                    (document.getElementById('search-bar') as HTMLInputElement).value = ""
                                                } 
                                                setSearchQuery("")
                                            }}
                                            ><CancelIcon /></IconButton>
                                        )
                                        }}
                                        >
                                </TextField>
                            </div>
                            <div style={{flex: "1", paddingLeft:"10px", "marginLeft": "auto"}} >   
                                {reportIsError && <Text color={Color.grey2}>Error downloading report</Text>}
                                <TertiaryButton onClick={() => {
                                    const isSuccess = downloadTrainingReport(selectedTenant.tenantId)

                                    if (!isSuccess) {
                                        setReportIsError(true)
                                    }
                                }}>
                                    Download report
                                </TertiaryButton>
                            </div>
                        </div>     
                    </TitleSection>
                    <Table>
                        <TableHeaderRow>
                        <HeaderCellWithHover onClick={() => { onSortClick("firstName") }}>First name{ sort === "firstName" && <Icon src={asc ? sortup : sortdown }/>}</HeaderCellWithHover>
                        <HeaderCellWithHover onClick={() => { onSortClick("lastName") }}>Last name{ sort === "lastName" && <Icon src={asc ? sortup : sortdown }/>}</HeaderCellWithHover>
                        <HeaderCellWithHover onClick={() => { onSortClick("report.isDue") }}>Training status{ sort === "report.isDue" && <Icon src={asc ? sortup : sortdown }/>}</HeaderCellWithHover>
                        {selectedTenant?.externalIdFieldName && <HeaderCellWithHover onClick={() => { onSortClick("externalIds") }}>{selectedTenant?.externalIdFieldName}{ sort === "externalIds" && <Icon src={asc ? sortup : sortdown }/>}</HeaderCellWithHover>}
                            <HeaderCell>Training record</HeaderCell>
                        </TableHeaderRow>
                        <Spacer/>
                        {data && data.users.map((r, i) => {
                            const isOpenRow = (openRow === i)
                            return <>
                                <TableRowWithHover onClick={e => isOpenRow ? setOpenRow(undefined) : setOpenRow(i)}>
                                    <HeaderCell><Text>{r.firstName}</Text></HeaderCell>
                                    <HeaderCell><Text>{r.lastName}</Text></HeaderCell>
                                    <HeaderCell><TextWithSignal signal={r.isDue === "Overdue" ? "Red" : "Green"}>{r.isDue}</TextWithSignal></HeaderCell>
                                    {selectedTenant?.externalIdFieldName && <HeaderCell>{r.externalId}</HeaderCell>}
                                    <TableCell><Underlined weight="light">{isOpenRow ? "Hide training record" : "Show training record"}</Underlined></TableCell>
                                </TableRowWithHover>
                                {isOpenRow && <TableRow><ExpandedTableCell colSpan={4}>
                                    <TrainingDetails items={r.report}/>
                                </ExpandedTableCell></TableRow>}
                                <Spacer/>
                            </>})}
                    </Table>
                    <Pager currentPage={currentPage} pageSize={pageSize} totalCount={data ? data.totalCount : 0} onNextClicked={() => setCurrentPage(currentPage + 1)} onPreviousClicked={() => setCurrentPage(currentPage - 1)}/> 
                </Scroller>
                :
                <EmptyText color={Color.grey2}>No training records available</EmptyText>
            </Content>
        </Wrapper> : <div></div>}
    </Authenticated>
}

export default Training

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${Color.lightGrey};
`

const Menu = styled(_Menu)`
    flex-shrink: 0;
`

const Content = styled.div`
    flex-grow: 1;
    height: 100vh;
    overflow-y: auto;
`

const SiteHeader = styled(_SiteHeader)`
    min-height: 100px;
    box-sizing: border-box;
`

const Scroller = styled.div`
    overflow: auto;
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
`

const Table = styled.table`
    margin-top: 20px;
    width: 100%;
`

const TableRowWithHover = styled(TableRow)`
    &:hover {
        cursor: pointer;
    }
`

const Spacer = styled.tr`
    height: 10px;
`

const Underlined = styled(Text)`
    text-decoration: underline;
`

const Pager = styled(_Pager)`
    margin-top: 20px;
`

const TitleSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const EmptyText = styled(Text)`
    margin: 40px;
`
const HeaderCellWithHover = styled(HeaderCell)`
    cursor: pointer !important;
`