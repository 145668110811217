import React from "react"
import { HTMLAttributes } from "react"
import styled from "styled-components"
import { Text as _Text } from "../atoms"

interface Props extends HTMLAttributes<HTMLDivElement>{
    title: string
    number: number
}

const TitleWithNumber: React.FC<Props> = ({title, number, ...props}) => {

    return <Wrapper {...props}>
        <Text size="large">{title}</Text>
        <Text size="normal" opacity="moderate">{`(${number})`}</Text>
    </Wrapper>
}

export default TitleWithNumber

const Wrapper = styled.div`
`

const Text = styled(_Text)`
    display: inline-block;
    margin-right: 5px;
`