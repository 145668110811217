import styled from 'styled-components';

interface Props {
    size?: number;
}

export const Paragraph = styled.p<Props>`
    font-size: ${({ size = 18}) => size}px;
    line-height: ${({ size = 18}) => size * 1.66}px;
    max-width: 480px;
    margin-bottom: 20px;
    @media only screen and (max-width: 750px) {
        font-size: ${({ size = 16}) => size}px;
        line-height: ${({ size = 16}) => size * 1.66}px;
    }
`;