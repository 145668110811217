import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin'
import { login, logout, isAuthenticated } from './auth.service';
import Bugsnag from '@bugsnag/js'

export default async (type: any, params: any) => {
    switch(type) {
        case AUTH_LOGIN: 
            const { username, password } = params
            await login(username, password)
            return Promise.resolve()
        case AUTH_LOGOUT:
            await logout()
            return Promise.resolve()
        case AUTH_ERROR:            
            const { status } = params
            if (status === 401 || status === 403) {
                logout()
                return Promise.reject()
            } else if (status !== 200) {
                Bugsnag.notify(params);
            }
            return Promise.resolve()            
        case AUTH_CHECK:
            return isAuthenticated() ? Promise.resolve() : Promise.reject()
        case AUTH_GET_PERMISSIONS:
            const profile = JSON.parse(sessionStorage.getItem('profile') as string)
            let roles = profile?.['https://facecheck.ai/roles'] || []
            return Promise.resolve(roles);
        default:
            return Promise.reject('Unknown method') 
    }
}
