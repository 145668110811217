import React, { HTMLAttributes } from "react"
import styled from "styled-components"
import { Color } from "../../styles/color"
import { Text } from "../atoms"

interface Props extends HTMLAttributes<HTMLDivElement> {
    firstName: string
    lastName: string
}

const UserListItem: React.FC<Props> = ({firstName, lastName, ...props}) => {

    return <Wrapper {...props}>
        <InitialsBackground>
            <Initials color={Color.white}>{firstName[0].toUpperCase() + lastName[0].toUpperCase()}</Initials>
        </InitialsBackground>
        <Text>{firstName + " " + lastName}</Text>
    </Wrapper>
}

export default UserListItem

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Initials = styled(Text)`
    line-height: 40px;
    height: 40px;
    width: 40px;
    text-align: center;
`

const InitialsBackground = styled.div`
    background: ${Color.green};
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 10px;
`