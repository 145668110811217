export type AnswerRecord = {
    _id: string;
    isIdentified: boolean;
    isAtLeastPartiallyIdentified: boolean;
    isFit: boolean;
    isFitNIdentified: string;
    answerSetId: string;
    userName: string;
    userMorsonId: number;
    timeStamp: Date;
    location: Location;
    questionSetType: string;
    questionSetTitle: string;
    questionsAnswered: QuestionsAnswered[];
    faceResult?: FaceResult;
    extraFaceResults?: FaceResult[];
    createdOn: Date;
    tenantId: string;
}

export type AnswerStatus = "Successful" | "Partially Successful" | "Unsuccessful"

export const AnswerStatusOf = (record?: AnswerRecord): AnswerStatus => {

    return                            !record ? "Unsuccessful"
                              : !record.isFit ? "Unsuccessful"
                        : record.isIdentified ? "Successful"
       : record?.isAtLeastPartiallyIdentified ? "Partially Successful"
                                              : "Unsuccessful"
}

export interface Location {
    latitude: number;
    longitude: number;
    accuracy: number;
    name?: string;
    geocodeResult: GeocodeResult;
}

export const premisesOf = (l: Location) => {
    const premises = l?.geocodeResult?.address_components?.filter(ac => ac.types.includes("premise"))
    return premises?.length ? premises[0].short_name : ""
}

export const streetAddressOf = (l: Location) => {
    const streetNumbers = l?.geocodeResult?.address_components?.filter(ac => ac.types.includes("street_number"))
    const roadNames = l?.geocodeResult?.address_components?.filter(ac => ac.types.includes("route"))

    let address = ""

    if (streetNumbers?.length) {
        address += streetNumbers[0].short_name + " "
    }

    if (roadNames?.length) {
        address += roadNames[0].short_name
    }

    address = address.trimEnd()

    return address ? address
         : l?.name ? l.name
                   : ""
}

export const postCodeOf = (l: Location) => {
    const postCode = l?.geocodeResult?.address_components?.filter(ac => ac.types.includes("postal_code"))
    return postCode?.length ? postCode[0].short_name : ""
}

export const displayNameOf = (l: Location, defaultLocationName: string) => {

    const premises = l?.geocodeResult?.address_components?.filter(ac => ac.types.includes("premise"))
    const streetNumbers = l?.geocodeResult?.address_components?.filter(ac => ac.types.includes("street_number"))
    const roadNames = l?.geocodeResult?.address_components?.filter(ac => ac.types.includes("route"))
    const postCodes = l?.geocodeResult?.address_components?.filter(ac => ac.types.includes("postal_code"))

    let name = ""

    if (premises?.length) {
        name += premises[0].short_name + ", "
    }

    if (streetNumbers?.length) {
        name += streetNumbers[0].short_name + " "
    }

    if (roadNames?.length) {
        name += roadNames[0].short_name + ", "
    }

    if (postCodes?.length) {
        name += postCodes[0].short_name
    }

    if (name.endsWith(", ")) {
        name = name.substring(0, name.length - 2)
    }

    name = name.trimEnd()

    return name ? name
      : l?.name ? l.name
           : !l ? defaultLocationName
                : "[Click here to view map]"
}

export interface GeocodeResult {
    address_components: AddressComponent[]
}

export interface AddressComponent {
    long_name: string,
    short_name: string,
    types: AddressComponentType[],
}

// This list is found at https://developers.google.com/maps/documentation/geocoding/overview#Types
export type AddressComponentType = "street_number"
                                 | "route"
                                 | "intersection"
                                 | "locality"
                                 | "sublocality"
                                 | "neighborhood"
                                 | "political"
                                 | "postal_town"
                                 | "administrative_area_level_1"
                                 | "administrative_area_level_2"
                                 | "administrative_area_level_3"
                                 | "administrative_area_level_4"
                                 | "administrative_area_level_5"
                                 | "country"
                                 | "postal_code"
                                 | "premise"
                                 | "subpremise"
                                 | "colloquial_area"
                                 | "plus_code"
                                 | "natural_feature"
                                 | "airport"
                                 | "park"
                                 | "point_of_interest"

export interface Location2 {
    latitude: number;
    longitude: number;
    accuracy: number;
}

export interface FaceResult {
    requestSucceeded: boolean;
    photoContainsFaceConfidence: number;
    faceIsMatchConfidence: number;
}

export interface OptionChosen {
    optionText: string;
    isCorrect: boolean;
    triggersFreeText: boolean;
}

export interface OptionsNotChosen {
    optionText: string;
    isCorrect: boolean;
    triggersFreeText: boolean;
}

export interface QuestionsAnswered {
    questionText: string;
    questionType: QuestionType;
    timeStamp: Date;
    location: Location2;
    faceResult: FaceResult;
    optionChosen: OptionChosen;
    optionsNotChosen: OptionsNotChosen[];
    freeText: string;
    link: string;
    linkWasFollowed: boolean;
}

export type QuestionType = "Single correct option"
                         | "Multiple correct options"
                         | "Free text"
                         | "Optional free text"
                         | "Photo"