import styled from "styled-components";

interface Props {
    size?: number;
}

export const Header = styled.h1<Props>`
    font-weight: bold;
    font-size: ${({ size = 60 }) => size}px;
    line-height: ${({ size = 60 }) => size * (1 + 1 / 3)}px;
    margin: auto;

    @media only screen and (max-width: 750px) {
        font-size: ${({ size = 60 }) => size / 1.875 }px;
        line-height: ${({ size = 60 }) => size / 1.875 * (1 + 1 / 3)}px;
    }
`;

