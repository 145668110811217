import React from "react"
import { HTMLAttributes } from "react"
import styled from "styled-components"
import { Color } from "../../styles/color"
import { Text } from "../atoms"
import { ProgressCircle as _ProgressCircle, TitleWithNumber, CardWithLoading as _CardWithLoading, LinkCard } from "../molecules"
import shield from "../../images/tick-on-shield.svg"

interface Props extends HTMLAttributes<HTMLDivElement> {
    users: Array<{name: string, trainingFraction: number}>
    fractionNotFullyTrained: number,
    isLoading: boolean
    error?: string
    hasLink: boolean
}

const TrainingCard: React.FC<Props> = ({isLoading, error, users, fractionNotFullyTrained, hasLink, ...props}) => {

    return <Card isLoading={isLoading} error={error} loadingColor={Color.green} {...props}>
        <TitleWrapper>
            <TitleWithNumber title="Staff" number={users.length}/>
            {hasLink && <LinkCard icon={shield} text="View training" link="/training"/>}
        </TitleWrapper>
        <SummaryWrapper>
            <ProgressCircle progress={fractionNotFullyTrained} trackColor={Color.blue8} progressColor={Color.red2} fontSize="24px"/>
            <SummaryText color={Color.red2} size="normal+">Not fully trained</SummaryText>
        </SummaryWrapper>
        <Scroller>
            <Table>
                <HeaderRow>
                    <HeaderCell><Text color={Color.grey2} size="small">Name</Text></HeaderCell>
                    <HeaderCell><RightAligned color={Color.grey2} size="small">Training</RightAligned></HeaderCell>
                </HeaderRow>
                {users.map(u => {
                    return <Row>
                        <Cell><Text>{u.name}</Text></Cell>
                        <Cell><RightAligned color={Color.blue}>{`${(u.trainingFraction * 100).toFixed(1)}%`}</RightAligned></Cell>
                    </Row>
                })}
            </Table>
        </Scroller>
    </Card>
}

export default TrainingCard

const Card = styled(_CardWithLoading)`
    display: flex;
    flex-direction: column;
`

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const SummaryWrapper = styled.div`
    display: flex;
    flex-direction: row;
    //width: calc(100% - 40px);
    margin: 20px auto;
    align-items: center;
`

const Scroller = styled.div`
    overflow-y: auto;
    padding: 0 25px;
    margin: 0 -25px -15px -25px;
`

const Table = styled.table`
    width: 100%;
`

const Row = styled.tr`
`

const HeaderRow = styled.tr`
    border-bottom: 1px solid ${Color.grey2};
`

const HeaderCell = styled.td`
    padding-bottom: 4px;
`

const Cell = styled.td`
    padding: 4px 0;
    text-align: left;
`

const RightAligned = styled(Text)`
    text-align: right;
`

const ProgressCircle = styled(_ProgressCircle)`
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 20px;
`

const SummaryText = styled(Text)`
    flex-shrink: 0;
    flex-grow: 0;
    width: 80px;
`