import { CircularProgress as _CircularProgress, TextField as _TextField, ThemeProvider } from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import { Color } from "../../styles/color"
import { theme } from "../../styles/theme"
import { PrimaryButton as _PrimaryButton, Text as _Text } from "../atoms"
import CloseIcon from '@material-ui/icons/Close'
import { useWebPut } from "../../hooks/useWebPut"
import { getCurrentUsername } from "../../providers/auth.service"
import config from '../../config'
import axios from 'axios'

interface Props extends React.HTMLAttributes<HTMLDivElement> {

    setIsVisible: (b: boolean) => void
}

const ChangePassword: React.FC<Props> = ({setIsVisible, ...props}) => {

    const [ oldPassword, setOldPassword ] = React.useState("")
    const [ newPassword, setNewPassword ] = React.useState("")
    const [ newPasswordConfirm, setNewPasswordConfirm ] = React.useState("")
    const [ oldPasswordIsFocused, setOldPasswordIsFocused ] = React.useState(false)
    const [ oldPasswordHasBeenFocused, setOldPasswordHasBeenFocused ] = React.useState(false)
    const [ newPasswordIsFocused, setNewPasswordIsFocused ] = React.useState(false)
    const [ newPasswordHasBeenFocused, setNewPasswordHasBeenFocused ] = React.useState(false)
    const [ newPasswordConfirmIsFocused, setNewPasswordConfirmIsFocused ] = React.useState(false)
    const [ newPasswordConfirmHasBeenFocused, setNewPasswordConfirmHasBeenFocused ] = React.useState(false)
    const [ isAuthenticating, setIsAuthenticating ] = React.useState(false)
    const [ error, setError ] = React.useState<string | undefined>(undefined)
    
    const buttonIsEnabled = (oldPassword.length && (newPassword.length >= 8) && (newPassword === newPasswordConfirm)) ? true
                                                                                                                      : false

    const {
        isLoading,
        isSuccess,
        start,
        reset,
    } = useWebPut(isSuccess => {
        if (!isSuccess) {
            setError("Something went wrong, please try again later")
        }
    })

    

    return <Overlay onClick={() => setIsVisible(false)} {...props}>
        <ThemeProvider theme={theme}>
            <Wrapper onClick={e => e.stopPropagation()}>
                { isSuccess ? <>
                    <SuccessText>Password successfully changed</SuccessText>
                    <PrimaryButton isEnabled={true} onClick={() => setIsVisible(false)}>Done</PrimaryButton>
                </>
                
                :

                <>
                    <CloseButton onClick={() => setIsVisible(false)}/>
                    <Title size="large">Change password</Title>
                    <TextField label="Current password" type="password" margin="normal" required value={oldPassword} onChange={e => setOldPassword(e.target.value)} onFocus={() => {setOldPasswordIsFocused(true); setOldPasswordHasBeenFocused(true);}} onBlur={() => setOldPasswordIsFocused(false)}/>
                    <ErrorLabel color={Color.red} size="small" isVisible={(oldPassword.length === 0) && !oldPasswordIsFocused && oldPasswordHasBeenFocused}>Please enter your current password</ErrorLabel>
                    <TextField label="New password" type="password" margin="normal" required value={newPassword} onChange={e => setNewPassword(e.target.value)} onFocus={() => {setNewPasswordIsFocused(true); setNewPasswordHasBeenFocused(true); }} onBlur={() => setNewPasswordIsFocused(false)}/>
                    <ErrorLabel color={Color.red} size="small" isVisible={(newPassword.length < 8) && !newPasswordIsFocused && newPasswordHasBeenFocused}>Please enter a new password at least 8 characters long</ErrorLabel>
                    <TextField label="Confirm new password" type="password" margin="normal" required value={newPasswordConfirm} onChange={e => setNewPasswordConfirm(e.target.value)} onFocus={() => {setNewPasswordConfirmIsFocused(true); setNewPasswordConfirmHasBeenFocused(true); }} onBlur={() => setNewPasswordConfirmIsFocused(false)}/>
                    <ErrorLabel color={Color.red} size="small" isVisible={(newPassword !== newPasswordConfirm) && !newPasswordConfirmIsFocused && newPasswordConfirmHasBeenFocused}>Passwords do not match</ErrorLabel>
                    
                    <PrimaryButton isEnabled={buttonIsEnabled} onClick={ async () => {
                        if (buttonIsEnabled && !isLoading && !isAuthenticating) {
                            setIsAuthenticating(true)                            
                            const isCorrectPassword = await checkPassword(getCurrentUsername(), oldPassword)
                            setIsAuthenticating(false)
                            
                            if (isCorrectPassword) {
                                reset()
                                await start(`${config.API_URL}/settings/me`, { password: newPassword, passwordConfirm: newPasswordConfirm})
                            }
                            else {
                                setError("Incorrect password")
                            }
                        }
                    }}>
                        { (isLoading || isAuthenticating) ? <CircularProgress style={{color: Color.black}} size="30px"/> : "Change password"}
                    </PrimaryButton>
                    <UrlErrorLabel color={Color.red} size="small" isVisible={(error?.length ?? 0) > 0}>{error}</UrlErrorLabel>
                </>}
            </Wrapper>
        </ThemeProvider>
    </Overlay>
}

export default ChangePassword

const Overlay = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: ${Color.translucentBlack};
    z-index: 20;
`

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: ${Color.white};
    width: 300px;
    height: 450px;
    border-radius: 10px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
    margin: auto;
    margin-top: 150px;
    padding: 40px;
`

const Title = styled(_Text)`
    margin: 0 auto 20px auto;
    text-align: center;
    align-self: center;
`

const TextField = styled(_TextField)`
    /* width: 200px; */
`

const CloseButton = styled(CloseIcon)`
    position: absolute;
    right: 10px;
    top: 10px;

    &:hover {
        cursor: pointer;
    }
`

const ErrorLabel = styled(_Text)<{isVisible: boolean}>`
    visibility: ${props => props.isVisible ? "visible" : "hidden"};
    margin-top: 4px;
`

const UrlErrorLabel = styled(ErrorLabel)`
    text-align: center;
`

const PrimaryButton = styled(_PrimaryButton)`
    margin-top: 30px;
    align-self: center;
`

const CircularProgress = styled(_CircularProgress)`
    margin-top: 5px;
`



const SuccessText = styled(_Text)`
    margin-top: 200px;
    text-align: center;
`

const checkPassword = async (username: string, password: string) => {
    try {
        const response = await axios({
            method: 'post',
            url: `https://${config.AUTH_0.AUTH_DOMAIN}/oauth/token`,
            data: {
                username,
                password,
                grant_type: 'password',
                audience: config.AUTH_0.AUTH_AUDIENCE,
                client_id: config.AUTH_0.AUTH_CLIENT_ID,
                scope: 'openid'
            },
            headers: { 'Content-Type': 'application/json' },
        })

        return (response.status >= 200) && (response.status <= 299)
    }
    catch (e) {
        return false
    }
}