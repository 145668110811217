import React from "react"
import styled from "styled-components"
import { Text } from "../atoms"
// import happy from "../../images/face-happy.svg"
import smile from "../../images/face-smile.svg"
import smileFilled from "../../images/face-smile-filled.svg"
import unsure from "../../images/face-unsure.svg"
import unsureFilled from "../../images/face-unsure-filled.svg"
import sad from "../../images/face-sad.svg"
import sadFilled from "../../images/face-sad-filled.svg"
import { Color } from "../../styles/color"
import { CardWithLoading } from "../molecules"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    isLoading: boolean
    satisfaction?: number
    setSatisfaction: (s?: number) => void
    error?: string
}

const FeedbackCard: React.FC<Props> = ({isLoading, satisfaction, setSatisfaction, error, ...props}) => {

    return <CardWithLoading isLoading={isLoading} loadingColor={Color.green} {...props}>
        <Title size="large">Satisfaction survey</Title>
        <Text color={Color.grey2}>How happy are you with your current cleaning services?</Text>
        <IconWrapper>
            <Face src={satisfaction === 2 ? smileFilled : smile} onClick={() => setSatisfaction(2)}/>
            <Face src={satisfaction === 1 ? unsureFilled : unsure} onClick={() => setSatisfaction(1)}/>
            <Face src={satisfaction === 0 ? sadFilled : sad} onClick={() => setSatisfaction(0)}/>
        </IconWrapper>
        {error && <Text size="small" color={Color.red2}>{error}</Text>}
    </CardWithLoading>
}

export default FeedbackCard

const Title = styled(Text)`
    margin-bottom: 10px;
`

const IconWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`

const Face = styled.img`
    width: 60px;
    height: 50px;
    object-fit: contain;
    margin: 20px;

    &:hover {
        cursor: pointer;
    }
`