import React, { HTMLAttributes } from "react"
import styled from "styled-components"
import { Card } from "../atoms"
import { Map, ProgressCircle as _ProgressCircle, TextWithIcon } from "../molecules"
import shield from "../../images/tick-on-shield.svg"
import faceId from "../../images/face-recognition.svg"
import { Color } from "../../styles/color"
import { Location } from "../../types"

interface Props extends HTMLAttributes<HTMLDivElement> {
    mapDefaultCenter: Location
    mapCenter: Location
    mapZoom: number
    mapMarkers: Location[]
    fractionFit: number
    fractionIdentified?: number
}

const ActivitySummary: React.FC<Props> = ({mapCenter, mapDefaultCenter, mapZoom, mapMarkers, fractionFit, fractionIdentified, ...props}) => {

    return <Wrapper {...props}>
        <Map defaultCenter={mapDefaultCenter}
             center={mapCenter ?? mapDefaultCenter}
             zoom={mapZoom}
             markers={mapMarkers}
             loadingElement={<ContainerElement />}
			 containerElement={<ContainerElement />}
			 mapElement={<ContainerElement />}
			 googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC_1Yl3_XRRLfHLewTmKBXe1JA-A-n96bg&v=3.exp&libraries=geometry,drawing,places"/>
        
        <FitForWorkCard>
            <CardTitle icon={shield}>Fit for Work</CardTitle>
            <ProgressCircle trackColor={Color.green4} progressColor={Color.green} fontSize="26px" progress={fractionFit}/>
        </FitForWorkCard>

        {(fractionIdentified !== undefined) && <FitForWorkCard>
            <CardTitle icon={faceId}>ID Verified</CardTitle>
            <ProgressCircle trackColor={Color.blue4} progressColor={Color.blue} fontSize="26px" progress={fractionIdentified}/>
        </FitForWorkCard>}
        
    </Wrapper>
}

export default ActivitySummary

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
`

const ContainerElement = styled.div`
    width: 500px;
    height: 200px;
    border-radius: 10px;
    /* flex-grow: 1; */
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
`

const FitForWorkCard = styled(Card)`
    margin-left: 40px;
    width: 200px;
    height: 200px;
    box-sizing: border-box;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    flex-grow: 0;
`

const CardTitle = styled(TextWithIcon)`
    
`

const ProgressCircle = styled(_ProgressCircle)`
    margin: 20px;
`