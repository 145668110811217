import React from 'react';
import Card from '@material-ui/core/Card';
import { CardContent as _CardContent } from '@material-ui/core';
import styled from 'styled-components';

export const ErrorCard = () => (
	<Card>
		<CardContent>You do not have permission to view this page</CardContent>
	</Card>
)
const CardContent = styled(_CardContent)` 
	// font-family: Nunito Sans, Roboto, sans-serif;
`


