import React, { FC } from 'react';
import { Grid as _Grid } from '@material-ui/core';
import styled from "styled-components";
import { getThemeConfig } from '../../styles/theme';
import { useLocation } from 'react-router-dom';
import wettonLogo from '../../images/wetton-logo.jpeg'


interface Props {}
export const LogoContainer: FC<Props> = () => {
	const location = useLocation()
	const isWetton = location.pathname.includes("wetton")
	
	return (
		<Grid container={true}>
			<Logo src={isWetton ? wettonLogo : themeConfig.img.logo}/>			
		</Grid>
	)
}

const themeConfig = getThemeConfig()

const Grid = styled(_Grid)`
	min-height: 6vmax;
	box-sizing: border-box;
	flex-direction: row;	
	color: ${themeConfig.color.titleText};   
	img {
			width: 9vmin;                    
			padding: 1rem;
			font-weight: bold;	
	}
`

const Logo = styled.img`
	max-height: 100px;
	object-fit: contain;
`