import React, { Fragment, FC } from 'react'
import {
	Datagrid,
	TextField,
	DatagridBody,
	FunctionField,
	TextInput,
	Pagination,
	Filter as RAFilter,
	List as RAList,
	TopToolbar,
	ExportButton as _ExportButton,
	Button,
	useListContext,
} from "react-admin";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { AnswerRecord, displayNameOf } from "./types";
import { AnswersRow } from "../../components/customGrid/AnswersRow";
import { CellWithSignal, Signal } from '../../components/customGrid/CellWithSignal';
import StatsBar from "./StatsBar";
import exporter from "./exporter";
import FilterByUserInput from "./FilterByUser";
import styled from "styled-components";
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';


const AnswersGridBody = (props: any) => (
	<DatagridBody {...props} row={<AnswersRow />} />
);

const AnswersGrid = (props: any) => {
	// reset the filters when leaving the page
	// useEffect(() => {
	// 	return () => props?.setFilters({});
	// }, []) // eslint-disable-line
	return <Datagrid {...props} body={<AnswersGridBody />} /> 
};

const Filter = (props: any) => (
	<FilterWrapper {...props}>
		<FilterByUserInput source="userId" filterValues={props.filterValues} />
		<TextInput source="test" label="test" />
	</FilterWrapper>
)

const ResponseList = (props: any) => {

	// Hack: this doesn't belong here
	const profile = JSON.parse(sessionStorage.getItem('profile') as string)
	const tenantId = profile['https://facecheck.ai/tenantId']
	const hasReports = (tenantId === "d9de592a-fe27-4b35-ad47-442b6cf63c48") 	// Benchmark
					|| (tenantId === "0f6bf2a4-39e2-4894-b231-3bf03fea67ac") 	// 4th Utility
	const hasAlerts = (tenantId === "71312787-718d-47a9-81be-c0189cd09226")		// FFW demo (old)
				   || (tenantId === "674df1af-050e-4bd6-af17-c53673121956") 	// Mace

	// const hasReports = false
	
	return <Fragment>
		<StatsBar />
		<List
			{...props}
			bulkActionButtons={false}
			perPage={25}
			pagination={<Pagination rowsPerPageOptions={[10, 25, 50]} {...props}/>}
			actions={<ListActions hasReports={hasReports} hasAlerts={hasAlerts} exporter={exporter}/>}
			filters={<Filter />}
			filter={{}}
			sort={{ field: 'timeStamp', order: 'DESC' }}>

			<AnswersGrid>
				<TextField
					source="userName"
					label="Name"
				/>
				<FunctionField
					source="location.name"
					label="Location"
					emptyText="[Click here to view map]"
					component="pre"	// Enables multiline
					render={(record: AnswerRecord) => displayNameOf(record.location, "Unknown")} />

				<FunctionField
					source="isIdentified"
					label="ID Verification"
					render={(record: AnswerRecord) => {
						
						const idValue: Signal = record.isIdentified ? "Green"
							  : record.isAtLeastPartiallyIdentified ? "Amber"
																	: "Red"
																	
						const idText =    record.isIdentified ? "Verified"
						: record.isAtLeastPartiallyIdentified ? "Partially Verified"
															  : "Not Verified"

						return <CellWithSignal value={idValue} text={idText} />
					}}
				/>
				<FunctionField
					source="isFit"
					label="Fit for Work"
					render={(record: any) => {

						const isFitValue = record.isFit ? "Green" : "Red"
						const isFitText = record.isFit ? "Successful" : "Unsuccessful"

						return <CellWithSignal value={isFitValue} text={isFitText} />
					}}
				/>
				<FunctionField
					source="questionSetTitle"
					label="Activity"
					render={(record: any) => record.questionSetTitle ? record.questionSetTitle : record.questionSetType ? `Out` : `In`}
				/>
				<FunctionField
					source="timeStamp"
					label="Time"
					render={(record: any) => moment(record.timeStamp).format('HH:mm DD MMM yyyy')}
				/>
			</AnswersGrid>
		</List>
	</Fragment>
}

interface IListActionProps {
	exporter: typeof exporter,
	hasReports: boolean,
	hasAlerts: boolean,
}

const ListActions: FC<IListActionProps> = (props) => {
    
	const { exporter, hasReports, hasAlerts } = props;
    const { resource } = useListContext();

	const history = useHistory()

    return (
        <TopToolbar>
            
			{ hasAlerts && <Button  label="Alerts" onClick={() => history.push("/alerts")}><NotificationsNoneOutlinedIcon/></Button> }
			{ hasReports && <Button label="Reports" onClick={() => history.push("/reports")}><TableChartOutlinedIcon /></Button> }
            <ExportButton resource={resource} exporter={exporter} />

        </TopToolbar>
    );
};

const ExportButton = styled(_ExportButton)`
	margin-left: 20px;
`

const FilterWrapper = styled(RAFilter)`
	div[data-source="userId"] {
		align-items: center;
	}
`;

const List = styled(RAList)`
	button[aria-label="Add filter"] {
		display: none;
	}
`;



export default ResponseList;
