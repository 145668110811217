import React from "react";
import { useInput } from 'react-admin';
import {
    FormControl as _FormControl,
    FormLabel as _FormLabel,
    FormHelperText,
} from '@material-ui/core';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import styled from "styled-components";

interface Props {
    input: {
        value: string;
        onChange: (val: string) => void;
    };
}

const PhoneNumberInput = (props: any) => {
    const {
        isRequired,
        input: { name, onChange, onBlur },
        meta: { touched, error },
    } = useInput(props);
    return (
        <FormControl>
            <FormLabel required={isRequired} error={error && touched}>
                Phone Number
            </FormLabel>
            <PhoneInput
                inputProps={{
                    name,
                    required: isRequired,                
                }}
                country={'gb'}
                value={name}
                enableSearch={true}
                onChange={onChange}
                onBlur={onBlur}      
                preferredCountries={['gb','us', 'ca', 'au', 'nz', 'sp']}
            />
            {error && touched && <Error>Invalid  number</Error>}
        </FormControl>
    )
}

export default PhoneNumberInput;

const FormControl = styled(_FormControl)`
    margin: 0.5em;
    margin-bottom: 200px;
    .react-tel-input input {
        height: 50px;
        width: 390px;
    }
`;

const FormLabel = styled(_FormLabel)`
    padding: 5px;
    font-size: 15px;
`;

const Error = styled(FormHelperText)`
    color: #f44336;
    // font-family: arial;
`
