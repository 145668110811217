import React, { useEffect } from "react";
import styled from "styled-components";
import { Paragraph } from "../../components/atoms";
import { useHistory, useLocation } from "react-router-dom";
import { getThemeConfig } from '../../styles/theme';
import config from "../../config";
import axios from "axios";

export const VerifyEmail = () => {
    const history = useHistory()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const email = queryParams.get('email');
    useEffect(() => {
        const verifyEmail = async () => {
            try {
                await axios({
                    method: 'post',
                    url: `${config.API_URL}/verify-email`,
                    data: {
                        email: email
                    },
                    headers: { 'Content-Type': 'application/json' },
                })
            }
            catch (e) {
                alert(e);
            }
        };
        if(email) verifyEmail();
    }, [email]);

    return (
        <PlaceholderWrapper>
            <Logo src="/images/logo.svg"/>
            <Paragraph>
                Your email is not verified, please download the Fit for Work app<br/>using the link below.
            </Paragraph>
            <Download onClick={() => history.push("/download")}>Download</Download>
        </PlaceholderWrapper>
    )
}

export default VerifyEmail;

const themeConfig = getThemeConfig()

interface Props {
    padding?: string;
}

const PlaceholderWrapper = styled.div<Props>`
    padding: ${({ padding = '150px 0 170px'}) => padding};
    text-align: center;
    p {
        text-align: center;
        margin: auto;
        max-width: none;
    }
`;

const Download = styled.div`
    border-radius: none;
    width: 120px;
	height: 40px;
	color: ${themeConfig.color.actionText};
	font-weight: bold;
	background-color: ${themeConfig.color.main};
    margin: auto;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
`

const Logo = styled.img`
    width: 180px;
    margin: 40px;
`