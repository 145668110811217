import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import config from './config';
import { getLocalProfile } from './providers/auth.service';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'proxy-polyfill'

Bugsnag.start({
  apiKey: config.BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_ENV,
  enabledReleaseStages: ['DEV', 'PROD', 'STAGING'], // prevent local error reporting
  onError: function (event) {
    try {
      const profile = getLocalProfile();
      const data = JSON.parse(profile || "");
      event.setUser(data[`https://facecheck.ai/tenantId`], data[`email`], data[`nickname`]);
    } catch (e) {}
  }
})

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) as React.ComponentClass;

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

