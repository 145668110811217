import React from 'react'
import { ThemeProvider } from '@material-ui/styles';
import { LogoContainer } from "./LogoContainer";
import { LoginContainer } from './LoginContainer';


export const LoginPage = (props: any) => {

	return (
		<ThemeProvider theme={props?.theme}>
			<LogoContainer {...props} />
			<LoginContainer {...props} />
		</ThemeProvider>
	)
}
