import React, { FC } from "react";
import CoreModal from '@material-ui/core/Modal';
import styled from "styled-components";

interface Props {
    onClose: VoidFunction;
    open: boolean;
}

const Modal: FC<Props> = ({ children, open, onClose }) => {
    return (
        <CoreModal open={open} onClose={onClose}>
            <Inner>
                {children}
            </Inner>
        </CoreModal>
    );
}

const Inner = styled.div`
    width: 400px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -200px;
    margin-left: -200px;
    background-color: white;
    border-radius: 8px;
    padding: 16px;
`;

export default Modal;