
import { stringify } from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import { fetchUtils } from 'react-admin';
import config from '../config';

const { API_URL } = config;

// TODO: this needs stricter types and a separate service for sessionStorage

const dataProvider = {
	getList: async (resource: string, params: any) => {
		const getProfile = JSON.parse(sessionStorage.getItem('profile') as string)
		const tenantId = getProfile['https://facecheck.ai/tenantId']
		let queryParams = {}
		if (resource === 'users') {
			const { pagination: { page, perPage }, sort } = params
			
			queryParams = {
				sort: `${sort.field},${sort.order.toLowerCase()}` || 'userId,desc',
				pagesize: perPage || 10,
				page: page || 1
			}
			const url = `${API_URL}/tenants/${tenantId}/${resource}?${stringify(queryParams)}`;
			const { json: { users: docs, totalCount } } = await fetchJson(url)
			return { data: docs.map((r: any) => ({ ...r, id: r.userId })), total: totalCount }
		}
		else if (resource === "fire-evacuation") {
			const { pagination: { page, perPage }, sort } = params

			queryParams = {
				sort: `${sort.field},${sort.order.toLowerCase()}` || 'lastName,desc',
				pagesize: perPage || 10,
				page: page || 1
			}

			const expectationId = (tenantId === "674df1af-050e-4bd6-af17-c53673121956") ? "9840c8e2-c5e2-49e9-840b-41e5ffd66d61"	// Mace
                                                                              			: "8f5c38c6-3a67-4a41-87b9-45be8ff44118"	// FFW demo (old)

			const url = `${API_URL}/tenants/${tenantId}/report/expectations/${expectationId}/live?${stringify(queryParams)}`
			const { json } = await fetchJson(url)

			return { data: json.map((r: any) => ({ ...r, id: r.userId })), total: json.length }
		}
		else if (resource === "recently-evacuated") {
			const { pagination: { page, perPage }, sort } = params

			queryParams = {
				sort: `${sort.field},${sort.order.toLowerCase()}` || 'lastName,desc',
				pagesize: perPage || 10,
				page: page || 1
			}

			const expectationId = (tenantId === "674df1af-050e-4bd6-af17-c53673121956") ? "4cd1d2f5-9646-4ad7-bd54-993a2f21b317"	// Mace
                                                                              			: "5c5267f6-665f-4eb5-b522-f702bacf1912"	// FFW demo (old)

			const url = `${API_URL}/tenants/${tenantId}/report/expectations/${expectationId}/live/?${stringify(queryParams)}`
			const { json } = await fetchJson(url)

			return { data: json.map((r: any) => ({ ...r, id: r.userId })), total: json.length }
		}
		else {
			const { pagination: { page, perPage }, sort, filter } = params
			queryParams = {
				sort: `${sort.field},${sort.order.toLowerCase()}` || '_id,desc',
				pagesize: perPage || 10,
				page: page || 1,
				filter: JSON.stringify(filter),
			}

			const url = `${API_URL}/tenants/${tenantId}/${resource}?${stringify(queryParams)}`;
			const { json: { data: { response, totalCount } } } = await fetchJson(url)

			return { data: response.map((r: any) => ({ ...r, id: r.answerSetId })), total: totalCount }
		}
	},
	getOne: async (resource: string, param: any) => {
		const { id } = param
		if (resource === 'settings') {
			const url = `${API_URL}/${resource}/${id}`;
			const { json: data } = await fetchJson(url)
			return { data: { id, ...data } }
		}
		if (resource === 'report' || resource === 'users') {
			const getProfile = JSON.parse(sessionStorage.getItem('profile') as string) // TODO: add service for getting items
			const tenantId = getProfile['https://facecheck.ai/tenantId']
			const url = `${API_URL}/tenants/${tenantId}/${resource}/${id}`;
			const { json: data } = await fetchJson(url)
			return { data: { id, ...data } }
		}
		if (resource !== 'users') {
			const getProfile = JSON.parse(sessionStorage.getItem('profile') as string) // TODO: add service for getting items
			const tenantId = getProfile['https://facecheck.ai/tenantId']
			const url = `${API_URL}/tenants/${tenantId}/${resource}/${id}`;
			const { json: { data } } = await fetchJson(url)
			return { data: { ...data, id: data.answerSetId } }
		}
	},
	create: async (resource: string, param: any) => {
		if (resource === 'users') {
			const uuid = uuidv4()
			const url = `${API_URL}/users/${uuid}`;
			const getProfile = JSON.parse(sessionStorage.getItem('profile') as string)
			const tenantId = getProfile['https://facecheck.ai/tenantId']
			const { data } = param
			const postData = {
				...data,
				tenantId: tenantId,
				userId: uuid,
				phoneNumber: `+${data.phoneNumber}`,
			};
			const { json: { user, message } } = await fetchJson(url, { method: 'PUT', body: JSON.stringify(postData) })
			return { data: { ...user, id: uuid }, message }
		}
	},
	update: async (resource: string, params: any) => {
		const url = `${API_URL}/${resource}/${params.id}`;
		const { json: data } = await fetchJson(url, { method: 'PUT', body: JSON.stringify(params.data) })
		return { data };
	},
	delete: async (resource: string, param: any) => {
		if (resource === 'users') {
			const { id } = param
			const url = `${API_URL}/${resource}/${id}`
			const response = await fetchJson(url, { method: 'DELETE' })
			return { data:{}, message: response.message }
		}
	}
};

export const fetchJson = (url: string, options: any = {}) => {
	const getAccess_token = sessionStorage.getItem('access_token')
	if (!options.headers) {
		options.headers = new Headers({ Accept: 'application/json', 'Authorization': `Bearer ${getAccess_token}` });
	}
	// add your own headers here

	return fetchUtils.fetchJson(url, options);
}

export default dataProvider;