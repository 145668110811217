import React, { FC } from 'react'
import styled from 'styled-components';
import { getThemeConfig } from '../../../styles/theme';

export interface ProgressBar {
    progress?: number;
}

export const ProgressBar: FC<ProgressBar> = ({progress}) => {
    const width = {width: `${progress}%`}

    return (
        <BarBg>
            <BarProgress style={width} />
        </BarBg>
    )
}

const themeConfig = getThemeConfig();

const BarBg = styled.div`
    display:block;
    background: rgba(0,0,0, .2);
    border-radius: 0 0 8px 8px;
    overflow:hidden;
`;

const BarProgress = styled.div`
    background: ${themeConfig.color.primary};
    height:10px;
`;
