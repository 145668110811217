
import React, { FC } from "react"
import { withGoogleMap, withScriptjs, GoogleMap, Marker } from "react-google-maps"

interface Props {
	latitude: number;
	longitude: number;
}

const _Map: FC<Props> = ({ latitude: lat, longitude: lng }) => {
	return (
		<GoogleMap
			defaultZoom={16}
			defaultCenter={{ lat, lng }}
		>
			<Marker position={{ lat, lng }} />
		</GoogleMap>
	)
};

const Map = withScriptjs(withGoogleMap(_Map))

export default Map;