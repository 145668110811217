import styled from "styled-components"

const TableCell = styled.td`
    padding: 15px 10px;

    :first-child {
        padding-left: 20px;
        border-top-left-radius: 5px; 
        border-bottom-left-radius: 5px;
    }

    :last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
`

export default TableCell