import React from 'react';
import { CustomRoute } from 'ra-core';
import { Admin, Resource } from 'react-admin';
import authProvider from "./providers/auth.provider";
import dataProvider from "./providers/data";
import { theme } from "./styles/theme";
import { users, responses, settings, fireEvac, fireCheckIn } from "./resources";
import './App.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { LoginPage } from './components/login'
import { createBrowserHistory as createHistory } from 'history';
import i18nProvider from "./providers/translate";
import Layout from "./layout";
import { Route } from 'react-router-dom';
import ReportDownloader from './resources/reports/reportDownloader'
import Alerts from './resources/alerts/Alerts';
import Download from './pages/download';
import Dashboard from './pages/dashboard';
import Training from './pages/training';
import Activity from './pages/activity';
import Landing from './pages/landing';
import Users from './pages/users';
import Messages from './pages/messaging';
import VerifyEmail from './pages/verify_email';

const history = createHistory();

const App = () => (
	<Admin
		title="Fit For Work"
		theme={theme}
		layout={Layout}
		authProvider={authProvider}
		loginPage={LoginPage}
		dataProvider={dataProvider}
		history={history}
		i18nProvider={i18nProvider}
		customRoutes={[
			<Route exact path="/reports" component={ReportDownloader} />,
			<Route exact path="/alerts" component={Alerts}/>,
			<Route<CustomRoute> exact path="/verify-email" component={VerifyEmail} noLayout/>,
			<Route<CustomRoute> exact path="/download" component={Download} noLayout/>,
			<Route<CustomRoute> exact path="/landing" component={Landing} noLayout/>,
			<Route<CustomRoute> exact path="/dashboard" component={Dashboard} noLayout/>,
			<Route<CustomRoute> exact path="/training" component={Training} noLayout/>,
			<Route<CustomRoute> exact path="/activity" component={Activity} noLayout/>,
			<Route<CustomRoute> exact path="/users/manage" component={Users} noLayout/>,
			<Route<CustomRoute> exact path="/messaging" component={Messages} noLayout/>,
			<Route<CustomRoute> exact path="/wetton" component={() => LoginPage({theme})} noLayout/>,
		]}>

		{(permissions: any) => {

			const profile = JSON.parse(sessionStorage.getItem('profile') as string)
			const tenantId = profile ? profile['https://facecheck.ai/tenantId'] : ""
			const hasFireEvacReport = (tenantId === "71312787-718d-47a9-81be-c0189cd09226") // FFW demo (old)
								   || (tenantId === "674df1af-050e-4bd6-af17-c53673121956") // Mace

			return [
				<Resource {...responses(permissions)} />,
				<Resource {...users(permissions)} />,
				hasFireEvacReport && <Resource {...fireEvac(permissions)}/>,
				hasFireEvacReport && <Resource {...fireCheckIn(permissions)}/>,
				<Resource {...settings(permissions)} />,
			]
		}}
	</Admin>
)

export default App
