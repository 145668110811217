import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { AnswerRecord, premisesOf, streetAddressOf, postCodeOf } from './types';
import moment from 'moment';
import { sentenceCase } from 'sentence-case';

const exporter = (records: AnswerRecord[]) => {
    if (records.length === 0) {
        throw new Error(`No data to export`);
    }

    let answerCount = 0;
    const questions = records.map(record => {
        const row = {
            [`userName`]: record.userName,
            [`premises`]: premisesOf(record?.location),
            [`streetAddress`]: streetAddressOf(record?.location),
            [`postcode`]: postCodeOf(record?.location),
            [`timeStamp`]: moment(record.timeStamp).format(`HH:mm D MMM yyyy`),
            [`isFit`]: record.isFit ? `Y` : `N`,
            [`identityIsVerified`]: record.isAtLeastPartiallyIdentified ? `Y` : `N`,
            [`isSuccessful`]: (record.isFitNIdentified === "HAPPY") ? `Y` : `N`,
            [`latitude`]: record.questionsAnswered?.[0]?.location?.latitude || `n/a`,
            [`longitude`]: record.questionsAnswered?.[0]?.location?.longitude || `n/a`,
            [`locationAccuracy`]: record.questionsAnswered?.[0]?.location?.accuracy || `n/a`,
            [`questionSetType`]: record.questionSetTitle ? record.questionSetTitle : record.questionSetType ? `Check Out` : `Check In`,
            [`questionsAnswered`]: record.questionsAnswered.length,
        } as Record<string, any>;
        record.questionsAnswered.map((answer, i) => {

            const isFreeText = (answer.questionType === "Free text")
							|| ((answer.questionType === "Optional free text") && answer.optionChosen.triggersFreeText)

			const answerText = isFreeText ? answer.freeText
						   	              : answer.optionChosen && answer.optionChosen.optionText

            row[`question.${i + 1}`] = answer.questionText;
            row[`question.${i + 1}.response`] = answerText;
            row[`question.${i + 1}.isCorrect`] = isFreeText ? "n/a"
                            : answer.optionChosen.isCorrect ? `Y`
                                                            : `N`;
            row[`question.${i + 1}.identityVerified`] = (answer.faceResult?.faceIsMatchConfidence ?? 0) > 80 ? `Y` : `N`;
            row[`question.${i + 1}.link`] = answer.link;
            row[`question.${i + 1}.linkWasFollowed`] = (answer.linkWasFollowed === true) ? 'Y'
                                                    : (answer.linkWasFollowed === false) ? 'N'
                                                                                         : '';
            answerCount = Math.max(i + 1, answerCount);
            return true;
        })
        return row;
    });

    let headers = [
        "userName",
        "timeStamp",
        "isFit",
        "identityIsVerified",
        "isSuccessful",
        "premises",
        "streetAddress",
        "postcode",
        "latitude",
        "longitude",
        "locationAccuracy",
        "questionSetType",
        "questionsAnswered",
    ];

    new Array(answerCount).fill(1).map((_, i) => {
        headers = headers.concat([
            `question.${i + 1}`,
            `question.${i + 1}.response`,
            `question.${i + 1}.isCorrect`,
            `question.${i + 1}.identityVerified`,
            `question.${i + 1}.link`,
            `question.${i + 1}.linkWasFollowed`,
        ]);
        return true;
    });

    const mapHeaders = (header: string) => sentenceCase(header);

    jsonExport(
        questions,
        {
            headers,
            mapHeaders,
        },
        (err: any, csv: string) => downloadCSV(csv, `activity-export-${+new Date()}`),
    );
}

export default exporter;