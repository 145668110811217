import React, { FC } from 'react'
import styled from 'styled-components';
import { getThemeConfig } from '../../../styles/theme';
import { Header as _Header} from "../../../components/atoms";
import { asPercent } from '../'
import { ProgressBar } from './ProgressBar'

export interface ProgressTile {
    title: string;
    amount: number;
    total: number;
    background?: string;
}

export const ProgressTile: FC<ProgressTile> = ({title, amount, total, background}) => {
    const percent = asPercent( amount, total )
    const bg = {backgroundImage: `url( ${background} )`}
    const isData = (amount + total) > 0;
    const label = isData ? `${amount} of ${total}` : ``;
    const percentLabel = isData ? `${percent}%` : ``;

	return (
        <Tile>
            <FlexWrapper style={bg}>
                <Header size={16}>{title}</Header>
                <DataWrapper>
                    <DataPrimary size={32}>{`${percentLabel}`}</DataPrimary>
                    <DataSecondary>{label}</DataSecondary>
                </DataWrapper>
            </FlexWrapper>
            {isData && <ProgressBar progress={percent} />}
        </Tile>
	);
}

const themeConfig = getThemeConfig()

const Tile = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0px 5px 11px -7px rgba(0,0,0,0.5);
    height: 100%;
`;

const FlexWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 25px 0 20px 112px;
    border-radius: 8px;
    background: white;
    background: #fff url( ${themeConfig.img.stats1} ) no-repeat 14px center;
    background-size: auto 63px;
    @media only screen and (max-width: 1024px) {
        padding: 25px 0 20px 76px;
        background-size: auto 45px;
    }
`

const Header = styled(_Header)`
    flex-basis: 45%;
    line-height: 1;
    font-weight:500;
`

const DataWrapper = styled.div`
    flex-basis: 55%;
    margin: auto 0;
`
const DataPrimary = styled(_Header)`
    display: inline-block;
    padding-right:12px;
    font-weight:600
`

const DataSecondary = styled.span`
    display: inline-block;
    font-size:12px;
    color: ${themeConfig.color.text}
`;

