import React, { HTMLAttributes } from "react"
import styled from "styled-components"
import { CircularProgress as _CircularProgress, } from '@material-ui/core'
import { Card, Text } from "../atoms"
import { Color } from "../../styles/color"

interface Props extends HTMLAttributes<HTMLDivElement> {
    isLoading: boolean
    loadingColor: string
    error?: string
}

const CardWithLoading: React.FC<Props> = ({isLoading, loadingColor, error, children, ...props}) => {

    return  <Card {...props}>
        {isLoading ? <Centred><CircularProgress style={{color: loadingColor}}/></Centred>
           : error ? <Centred color={Color.red}><Text>{error}</Text></Centred>
                   : children}
    </Card>
}

export default CardWithLoading

const Centred = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`

const CircularProgress = styled(_CircularProgress)`
    
`