import { Authenticated } from 'ra-core'
import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Menu as _Menu } from '../../components/layouts'
import config from '../../config'
import { useWebGet } from '../../hooks/useWebGet'
import { smsHistoryResponse, UserStatusReponse } from '../../types'
import { TableHeaderRow, Text, TableRow } from '../../components/atoms'
import { getFirstName, getLastName, getTenants, logout, usesWettonLogin } from '../../providers/auth.service'
import { Color } from '../../styles/color'
import { AdminHeader, HeaderCell } from '../../components/molecules'
import { menuOptionsForConfig } from '../../components/layouts/menu'
import { useHistory, useLocation } from 'react-router-dom'
import {
    CircularProgress, Table, TextField, Typography
} from '@material-ui/core'
import queryString from 'query-string'
import { Radio as _Radio, RadioGroup, Button as _Button } from '@material-ui/core';
import {
    FormControl as _FormControl,
    FormControlLabel as _FormControlLabel
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { CustomLabelTypography } from '../../components/atoms/CustomLabelTypography'
import axios from "axios"
import { getAccessToken } from "../../providers/auth.service"
import { SiteHeader as _SiteHeader, ChangePassword, Pager } from '../../components/organisms'
import { Tenant } from '../../types'
import Modal from "../../components/modal";
import { DateTime } from 'luxon'

const Messages: FC = () => {
    const history = useHistory()
    const location = useLocation()
    const tenants = getTenants()
    const [modalOpen, setModalOpen] = useState(false);
    if (!tenants?.length) {
        history.push("/login")
    }

    const { tenantId } = queryString.parse(location.search)

    const tenant = tenants?.[ 0 ]         // TODO: Proper check for undefined
    const siteConfig = tenant?.siteConfig   // TODO: Type narrowing to avoid needing this check for undefined
    const subTenants = tenant?.subTenants?.length ? tenant.subTenants : undefined
    const tenantsToUse = (siteConfig?.managesSubTenants && subTenants) ? [ tenant, ...subTenants ] : [ tenant ]

    const selectedTenant = tenantId ? (tenantsToUse.filter(t => t?.tenantId === tenantId)[ 0 ] ?? tenantsToUse[ 0 ]) : tenantsToUse[ 0 ]

    const [ changePasswordIsVisible, setChangePasswordIsVisible ] = React.useState(false)
    const [ message, setMessage ] = React.useState('')

    const [ alertType, setAlertType ] = React.useState('checkedInUsers')
    const [ currentPage, setCurrentPage ] = React.useState(1)
    const isReady = true
    const pageSize = 10
    const [ postingStatus, setPostingStatus ] = React.useState<string>('idle')
    const [ postingError, setPostingErrorr ] = React.useState<any>(null)
    const [ recipientCount, setRecipientCount ] = React.useState<number>(0)
    /**
     * Make call to get count only once
     */
    const {
        isLoading,
        errorCode,
        data,
        refetch
    } = useWebGet<UserStatusReponse>(`${config.API_URL}/tenants/${selectedTenant?.tenantId}/users/usersStatusCount`, !!isReady)
   
    const { //isLoading: activityIsLoading,
        //errorCode: activityErrorCode,
        refetch: refetchSmsHistory,
        data: smsData } = useWebGet<smsHistoryResponse>(`${config.API_URL}/tenants/${selectedTenant?.tenantId}/sms-history?pagesize=${pageSize}&page=${currentPage}`,
                                                           !!selectedTenant)

    useEffect(() => {
        const count: any = alertType === 'allUsers' ? data?.totalUsersCount : data?.checkedInUsersCount
        setRecipientCount(count)
    }, [ alertType, data ])

    const handleSubmit = async () => {
        setPostingStatus('START')
        setPostingErrorr(null)
        try {
            axios.request({
                method: "post",
                url: `${config.API_URL}/tenants/${selectedTenant?.tenantId}/alert?version=2`,
                headers: { Authorization: "Bearer " + getAccessToken() },
                data: { alertType, message }
            })
            setModalOpen(true)
            setPostingStatus('END')
            setMessage('')
            setAlertType('checkedInUsers')
        } catch (e) {
            setPostingErrorr(e)
            setPostingStatus('END')
        }
    }

    return <Authenticated>
        {siteConfig && tenantsToUse && selectedTenant ? <Wrapper>
            {changePasswordIsVisible && <ChangePassword setIsVisible={setChangePasswordIsVisible} />}

            <Menu options={menuOptionsForConfig(siteConfig)} selectedOption="Messaging" />
            <Content>
                <AdminHeader logoImageName={tenant?.logoImageName} firstName={getFirstName() ?? ""} lastName={getLastName() ?? ""} logout={() => { logout(); history.push(usesWettonLogin() ? "/wetton" : "/login") }} setChangePasswordIsVisible={setChangePasswordIsVisible} />

                <SiteHeader tenants={tenantsToUse as Tenant[]}
                    changeTenantText={siteConfig.changeSubTenantText ?? "Change dashboard"}
                    headerLabel='Send a message to staff at'
                    selectedTenant={selectedTenant}
                    setSelectedTenant={t => history.push(location.pathname + "?tenantId=" + t.tenantId)} alwaysShowLabel={true} />

                {(isLoading || postingStatus === 'START') && <ProgessWrapper><CircularProgress color="primary" /></ProgessWrapper>}
                {(errorCode || postingError) && <Alert severity="error"><AlertTitle>Error</AlertTitle>{`Something went wrong getting users, please refresh to try again (${errorCode || postingError})`}</Alert>}
                {data && <MessageContainer>
                    <MessageForm >
                        <MessageFormWrapper>
                            <FormControl >
                                <FormTitle>Who would you like to send a message to?</FormTitle>
                                <RadioGroup aria-label="quiz" name="alertType" value={alertType} onChange={(e) => {
                                    setAlertType(e.target.value);
                                }}>
                                    <FormControlLabel value="checkedInUsers" control={<Radio />}
                                        label={<CustomLabelTypography labelText='Anyone currently checked in' count={data?.checkedInUsersCount} />} />
                                    <FormControlLabel value="allUsers" control={<Radio />}
                                        label={
                                            <CustomLabelTypography labelText={`Everyone at ${selectedTenant?.tenantName}`} count={data?.totalUsersCount} />
                                        } />
                                </RadioGroup>
                            </FormControl>

                            <FormControl>
                                <FormTitle>Please type your message below</FormTitle>
                                <TextField placeholder="Type your message in here..." id="message" value={message} multiline={true} rows={3} variant='outlined' onChange={(e) =>
                                    setMessage(e.target.value)
                                } />
                            </FormControl>

                        </MessageFormWrapper>
                        <ButtonWrapper>
                            <Button variant="contained" onClick={handleSubmit}>
                                {`Send to ${recipientCount} ${recipientCount > 1 ? 'recipients' : 'recipient'}`}
                            </Button>
                        </ButtonWrapper>
                    </MessageForm>
                </MessageContainer>}
                <Table>
                <TableHeaderRow>
                        <HeaderCell>Receiver Name</HeaderCell>
                        <HeaderCell>Phone Number</HeaderCell>
                        <HeaderCell>Message</HeaderCell>
                        <HeaderCell>Timestamp</HeaderCell>
                </TableHeaderRow>
                <Spacer/>
                               
                {smsData && smsData.response.map((a, i) => {

                    return <>
                        <TableRowWithHover >
                            <HeaderCellWithHover><Text>{a.username}</Text></HeaderCellWithHover>
                            <HeaderCellWithHover><Text>{a.phoneNumber}</Text></HeaderCellWithHover>
                            <HeaderCellWithHover><Text>{a.message}</Text></HeaderCellWithHover>
                            <HeaderCellWithHover><Text weight="light">{DateTime.fromISO(a.timeStamp).toFormat("HH:mm | d MMM yyyy")}</Text></HeaderCellWithHover>
                        </TableRowWithHover>
                        <Spacer/>
                    </>
                })}
            </Table>
            {smsData && <Pager currentPage={currentPage} pageSize={pageSize} totalCount={smsData.totalCount} onNextClicked={() => setCurrentPage(currentPage + 1)} onPreviousClicked={() => setCurrentPage(currentPage - 1)}/>}
            
            </Content>
            
            <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100vh" }}
            >
            <Modal open={modalOpen} onClose={() => {refetchSmsHistory(); refetch(); setModalOpen(false)}}>
                <form onSubmit={() => {refetchSmsHistory(); refetch();  setModalOpen(false)}}>
                <header >
                    <span onClick={() => {refetchSmsHistory(); refetch();  setModalOpen(false)}} className="close-button topright">&times;</span>
                </header>
                    <Typography variant="subtitle2">
                    </Typography>
                    <Typography>
                        <br />
                        Message request received - you can leave this page  
                    </Typography>
                    <Typography  variant="subtitle2">
                    <br />
                         Most messages are sent instantly but some can take a little longer to reach everyone
                    </Typography>
                    <br/>
                    <br/>
                   
                </form>
                <footer style={{"textAlign":"center"}}>
                    <Button variant="contained" color="secondary" onClick={() => {refetchSmsHistory(); refetch();  setModalOpen(false)}}>
                        Ok
                    </Button>
                </footer>
            </Modal> 
            <br></br>
            </div><div>
            
            </div>                          
        </Wrapper> : <div></div>}
    </Authenticated>
}

export default Messages

const ProgessWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding:2em;
    justify-content: center;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${Color.lightGrey};
`
const Content = styled.div`
    flex-shrink: 1;
    flex-grow: 1;
    height: 100vh;
    overflow-y: auto;

`

const Menu = styled(_Menu)`
    flex-shrink: 0;
`
const FormControlLabel = styled(_FormControlLabel)`
    &:hover {
        background-color: ${Color.grey16};
    }
`
const MessageFormWrapper = styled.div`
    display: flex;
    flex-direction: row;
`

const Radio = styled(_Radio)`
    &.MuiRadio-colorSecondary.Mui-checked {
        color:  #68e1db
    }
`

const SiteHeader = styled(_SiteHeader)`
    min-height: 100px;
    box-sizing: border-box;
`

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-wrap: nowrap;
    width: 100%;
    padding: 35px;
    box-sizing: border-box;
`
const MessageForm = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: ${Color.white};
    box-sizing: border-box;
    padding: 25px;
    min-height: 20px;
    justify-content: space-between;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 1em;
`
const FormControl = styled(_FormControl)`
    flex:1;
`


const Button = styled(_Button)`
    &.MuiButton-contained{
        text-transform: inherit;
        background-color: ${Color.black};
        color: ${Color.green};
        cursor: pointer!important;
        &:hover {
            cursor: default;
            background-color: ${Color.black};
        }
        &:disabled {
            background-color: ${Color.green};
            opacity: 0.3;
        }
    }
`
const FormTitle = styled.p`
    font-size: 20px;
    margin-bottom: 1em;
`
const Spacer = styled.tr`
    height: 10px;
`
const HeaderCellWithHover = styled(HeaderCell)`
    cursor: pointer !important;
`

const TableRowWithHover = styled(TableRow)`
    &:hover {
        cursor: pointer;
    }
`