import React, { HTMLAttributes } from "react"
import styled from "styled-components"
import useOnClickOutside from "../../hooks/useOnClickOutside"
import { Color } from "../../styles/color"
import { Tenant, Site } from "../../types"
import { Text as _Text } from "../atoms"
import { DateRangePicker as _DateRangePicker } from 'react-date-range'
import { DateTime } from "luxon"
import { TextField } from "@material-ui/core"
import ClearIcon from '@material-ui/icons/Clear'
import { InputAdornment, ThemeProvider } from '@material-ui/core'
import { theme } from "../../styles/theme"

interface Props extends HTMLAttributes<HTMLDivElement> {
    tenants: Tenant[]
    selectedTenant: Tenant
    changeTenantText: string
    alwaysShowLabel?: boolean
    headerLabel?: string
    selectedSite?: Site
    setSelectedSite?: (s?: Site) => void
    setSelectedTenant: (t: Tenant) => void
    startDate?: DateTime
    setStartDate?: (d: DateTime) => void
    endDate?: DateTime
    setEndDate?: (d: DateTime) => void
}

const SiteHeader: React.FC<Props> = ({tenants, headerLabel, alwaysShowLabel, selectedTenant, changeTenantText, setSelectedTenant, selectedSite, setSelectedSite, startDate, setStartDate, endDate, setEndDate, ...props}) => {
    const [siteMenuIsOpen, setSiteMenuIsOpen] = React.useState(false)
    const [siteSearchTerm, setSiteSearchTerm] = React.useState("")
    const [tenantMenuIsOpen, setTenantMenuIsOpen] = React.useState(false)
    const [datePickerIsOpen, setDatePickerIsOpen] = React.useState(false)
    const [possibleStartDate, setPossibleStartDate ] = React.useState(startDate)
    const [possibleEndDate, setPossibleEndDate ] = React.useState(endDate)
    const siteMenuRef = React.useRef(null)
    const tenantMenuRef = React.useRef(null)
    const datePickerRef = React.useRef(null)
    useOnClickOutside(siteMenuRef, (_) => { setSiteMenuIsOpen(false); setTenantMenuIsOpen(false); setDatePickerIsOpen(false); })
    useOnClickOutside(tenantMenuRef, (_) => { setSiteMenuIsOpen(false); setTenantMenuIsOpen(false); setDatePickerIsOpen(false); })
    useOnClickOutside(datePickerRef, (_) => { setSiteMenuIsOpen(false); setTenantMenuIsOpen(false); setDatePickerIsOpen(false); })

    return <ThemeProvider theme={theme}>
        <Wrapper {...props}>

        {setSelectedSite && (selectedTenant.sites?.length > 0) && <TextAndButtonContainer>
            <Text color={Color.white} size="extra large">{selectedSite ? selectedSite.name : "All sites"}</Text>
            {(selectedTenant.sites?.length > 1) && setSelectedSite && <ButtonAndMenuContainer>
                <Button color={Color.green} size="small" onClick={() => setSiteMenuIsOpen(true)}>Change site</Button>
                {siteMenuIsOpen && <Menu ref={siteMenuRef}>
                    <SearchMenuItem>
                        <SearchField label="Search"
                                     variant="outlined"
                                     margin="none"
                                     value={siteSearchTerm}
                                     onChange={e => setSiteSearchTerm(e.target.value)}
                                     InputProps={{endAdornment: <InputAdornment position="end">{ (siteSearchTerm.length > 0) && <ClearButton onClick={() => setSiteSearchTerm("")}/>}</InputAdornment>}}>
                        </SearchField>
                        {/* <ClearButton onClick={() => setSiteSearchTerm("")}/> */}
                    </SearchMenuItem>
                    <MenuItem onClick={() => {setSelectedSite(undefined); setSiteMenuIsOpen(false);}}><Text>All sites</Text></MenuItem>
                    {selectedTenant.sites.filter(s => siteSearchTerm ? s.name.toLowerCase().includes(siteSearchTerm.toLowerCase()) : true)
                                         .map(s => <MenuItem onClick={(_) => {setSelectedSite(s); setSiteMenuIsOpen(false)}}><Text>{s.name}</Text></MenuItem>)}
                </Menu>}
            </ButtonAndMenuContainer>}
        </TextAndButtonContainer>}

        {(tenants.length > 1) && <TextAndButtonContainer>
                <Text color={Color.white} size="extra large">{headerLabel} {selectedTenant.tenantName}</Text>
            <ButtonAndMenuContainer>
                <Button color={Color.green} size="small" onClick={() => setTenantMenuIsOpen(true)}>{changeTenantText}</Button>
                {tenantMenuIsOpen && <Menu ref={tenantMenuRef}>
                    {tenants.map(t => t.subTenants && t.subTenants.length>0 && <MenuItem onClick={(_) => {setSelectedTenant(t); setTenantMenuIsOpen(false)}}><Text>{t.tenantName}</Text></MenuItem>)}
                    {/* distinguish subtenants */}
                    {tenants.map(t => (!t.subTenants || t.subTenants.length === 0) && <SubtanantContainer> 
                        <Text>{"\u25CF"}</Text> {/*bullet point */}
                        <MenuItem onClick={(_) => {setSelectedTenant(t); setTenantMenuIsOpen(false)}}><Text>{t.tenantName}</Text></MenuItem> 
                    </SubtanantContainer>)}
                </Menu>}
            </ButtonAndMenuContainer>
            </TextAndButtonContainer>}
        {(tenants.length === 1) && alwaysShowLabel && <TextAndButtonContainer>
                <Text color={Color.white} size="extra large">{headerLabel} {selectedTenant.tenantName}</Text>
        </TextAndButtonContainer>}

        { startDate && setStartDate && endDate && setEndDate && possibleStartDate && possibleEndDate &&
            <TextAndButtonContainer>
                <ButtonAndMenuContainer>
                    <Text color={Color.white} size="extra large">{`${startDate.toFormat("d MMM yyyy")} - ${endDate.toFormat("d MMM yyyy")}`}</Text>
                    <Button color={Color.green} size="small" onClick={() => setDatePickerIsOpen(true)}>Change date range</Button>
                    { datePickerIsOpen &&
                        <DateRangeWrapper ref={datePickerRef}>
                            <DateRangePicker ranges={[{startDate: possibleStartDate.toJSDate(), endDate: possibleEndDate.toJSDate(), key: "selection"}]}
                                             rangeColors={[Color.grey]}
                                             showDateDisplay={false}
                                             onChange={(ranges) => {
                                                if (ranges?.selection?.startDate) {
                                                    setPossibleStartDate(DateTime.fromJSDate(ranges.selection.startDate))
                                                }

                                                if (ranges?.selection?.endDate) {
                                                    setPossibleEndDate(DateTime.fromJSDate(ranges.selection.endDate))
                                                }
                                            }}/>
                            <DateButtonWrapper>
                                <SecondaryButton onClick={() => { setPossibleStartDate(startDate); setPossibleEndDate(endDate); setDatePickerIsOpen(false) }}>Cancel</SecondaryButton>
                                <PrimaryButton onClick={() => { setStartDate(possibleStartDate); setEndDate(possibleEndDate); setDatePickerIsOpen(false); }}>Ok</PrimaryButton>
                            </DateButtonWrapper>
                        </DateRangeWrapper>}
                </ButtonAndMenuContainer>
            </TextAndButtonContainer>}
        </Wrapper>
    </ThemeProvider>
}

export default SiteHeader

const Wrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    justify-content: flex-start;
    padding: 0 60px;
    background: ${Color.black};
`

const SubtanantContainer = styled.div`
    padding-left: 30px;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    justify-content: flex-start;
    align-items: center;
`

const Button = styled(_Text)`
    text-decoration: underline;
    margin-top: 5px;

    &:hover {
        cursor: pointer;
    }
`

const ButtonAndMenuContainer = styled.div`
    position: relative;
    display: inline-block;
`

const Menu = styled.div`
    position: absolute;
    top: 30px;
    width: 400px;
    background: ${Color.white};
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border: 1px solid ${Color.grey8};
    overflow: hidden;
    z-index: 10;
    max-height: 600px;
    overflow: auto;
`

const MenuItem = styled.div`

    padding: 10px;

    &:hover {
        background: ${Color.grey16};
        cursor: pointer;
    }
`

const SearchMenuItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
`

const DateRangePicker = styled(_DateRangePicker)`
    color: ${Color.black};
    font-family: "Hero New";
`

const Text = styled(_Text)`
    line-height: 1.3em;
    /* display: inline-block; */
`

const TextAndButtonContainer = styled.div`
    // max-width: 500px;
    margin: 10px 40px 10px 0;
`

const DateRangeWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 100px;
    right: -20px;
    //width: 400px;
    z-index: 10;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border: 1px solid ${Color.grey8};
    overflow: hidden;
    background: ${Color.white};
`

const DateButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px 100px;
`

const PrimaryButton = styled.div`
    background-color: ${Color.green};
    color: ${Color.black};
    width: 100px;
    height: 30px;
    border-radius: 5px;
    text-align: center;
    line-height: 30px;
    margin-left: 20px;

    &:hover {
        cursor: pointer;
    }
`

const SecondaryButton = styled(PrimaryButton)`

    background-color: ${Color.white};
    color: ${Color.grey};
    border: 1px solid ${Color.grey};
`

// const FeedbackButton = styled(PrimaryButton)`
//     background-color: ${Color.transparent};
//     color: ${Color.white};
//     border: 1px solid ${Color.white};
//     margin: 10px;
//     width: 200px;
// `

const ClearButton = styled(ClearIcon)`
    width: 20px;
    height: 20px;
    color: ${Color.grey};

    &:hover {
        cursor: pointer;
    }
`

const SearchField = styled(TextField)`
    flex-grow: 1;
`
