import * as React from 'react';
import { Layout, Sidebar, Notification, AppBar } from 'react-admin';
import Menu from "./Menu";

export default (props: any) => (
    <Layout
        {...props}
        appBar={AppBar}
        sidebar={Sidebar}
        menu={Menu}
        notification={Notification}
    />
)
