import React from "react"
import styled from "styled-components"
import { Color } from "../../styles/color"
import { Text, Opacity, Weight } from "../atoms"
import { CardWithLoading, LinkCard } from "../molecules"
import iAuditor from "../../images/iAuditor-icon.svg"

type CellInfo = {
    text: string
    opacity: Opacity
    weight: Weight
    color: string
}

interface Props extends React.HTMLAttributes<HTMLDivElement>{
    title: string
    headers: string[]
    rows: CellInfo[][]
    isLoading: boolean
    error?: string
    hasLink: boolean
}

const TableCard: React.FC<Props> = ({isLoading, error, title, headers, rows, hasLink, ...props}) => {

    return <CardWithLoading isLoading={isLoading} error={error} loadingColor={Color.green} {...props}>
        <TitleWrapper>
            <Text size="large">{title}</Text>
            {hasLink && <LinkCard icon={iAuditor} text="View report" link="https://app.safetyculture.com/inspections"/>}
        </TitleWrapper>
        {rows.length ? <Table>
            <HeaderRow>{headers.map(h => <HeaderCell><HeaderText size="small" opacity="moderate">{h}</HeaderText></HeaderCell>)}</HeaderRow>
            {rows.map(r => <Row>
                {r.map(c => <Cell><Text color={c.color} opacity={c.opacity} weight={c.weight}>{c.text}</Text></Cell>)}
            </Row>)}
        </Table>
        :
        <Text color={Color.grey2}>None</Text>}
    </CardWithLoading>
}

export default TableCard

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
`

const Table = styled.table`
    width: 100%;
`

const HeaderCell = styled.th`
    vertical-align: bottom;
    text-align: left;
    padding: 10px 4px;
    box-sizing: border-box;
`

const HeaderText = styled(Text)`
    /* position: absolute; */
    //bottom: 4px;
`

const HeaderRow = styled.tr`
    border-bottom: 1px solid ${Color.grey};
`

const Row = styled.tr`
`

const Cell = styled.td`
    padding: 10px 4px;
    text-align: left;
`