import React, { useEffect } from "react";
import styled from "styled-components";
import { Header, Paragraph } from "../../components/atoms";

export const Download = () => {
    
    const appStore = `https://apps.apple.com/us/app/fit-for-work/id1495784374?ls=1`
    const playStore = `https://play.google.com/store/apps/details?id=com.londontechworks.facecheck`

    useEffect(() => {
        function redirect() {

            const os = getOSName();
            if (os === "iOS") {
                window.location.replace(appStore);
                return;
            }
        
            if (os === "Android") {
                window.location.replace(playStore);
                return;
            }   
        }

        redirect()
    });


    return (
        <PlaceholderWrapper>
            <Logo src="/images/logo.svg"/>
            <Header>
                Downloading the Fit for Work app
            </Header>
            <Paragraph>
                If you are not redirected, please use the download links below.
            </Paragraph>
            <div>
                <a href={appStore}>
                    <AppStoreLogo src="/images/appStore.png" alt="Download App Store" />
                </a>
                <a href={playStore}>
                    <AppStoreLogo src="/images/playStore.png" alt="Download Play Store" />
                </a>
            </div>
        </PlaceholderWrapper>
    )
}

export default Download;


interface Props {
    padding?: string;
}

const PlaceholderWrapper = styled.div<Props>`
    padding: ${({ padding = '100px 0 170px'}) => padding};
    text-align: center;
    p {
        text-align: center;
        margin: auto;
        max-width: none;
    }
`;

const Logo = styled.img`
    width: 180px;
    margin: 40px;
`

const AppStoreLogo = styled.img`
    margin: 20px;
    width: 250px;
`;


const getOSName = () => {
    let os = "Unknown OS";
    if (navigator.appVersion.indexOf("Win") !== -1) os = "Windows";
    if (navigator.appVersion.indexOf("Mac") !== -1) os = "MacOS";
    if (navigator.appVersion.indexOf("X11") !== -1) os = "UNIX";
    if (navigator.appVersion.indexOf("Linux") !== -1) os = "Linux";
    if (navigator.appVersion.indexOf("iPhone") !== -1) os = "iOS";
    if (navigator.appVersion.indexOf("Android") !== -1) os = "Android";
    return os;
}