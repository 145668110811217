import React from "react"
import styled from "styled-components"
import { Card, Text } from "../atoms"
import folder from "../../images/folder.svg"
import { Color } from "../../styles/color"
import { Link } from "../../types"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    quickLinks: Link[]
}

const QuickLinksCard: React.FC<Props> = ({quickLinks, ...props}) => {

    return <Wrapper {...props}>
        <Title size="large">Quick links</Title>
        <LinksWrapper>
            {quickLinks.map(ql => <FolderContainer>
                <Folder src={folder} onClick={() => window.open(ql.url, "_blank")}/>
                <Label size="small" color={Color.grey2}>{ql.label}</Label>
            </FolderContainer>)}
        </LinksWrapper>
    </Wrapper>
}

export default QuickLinksCard

const Wrapper = styled(Card)`
    min-width: 200px;
    max-width: 300px;
    max-height: 400px;
`

const Title = styled(Text)`

`

const LinksWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: start;
`

const Folder = styled.img`
    width: 60px;
    height: 50px;
    object-fit: contain;
    &:hover {
        cursor: pointer;
    }
`

const FolderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    flex-basis: min-content;
`

const Label = styled(Text)`
    margin-top: 5px;
    text-align: center;
`