import { Config } from "./types";

const config: Config = {
    API_URL: "https://api.fitforwork.ai",
    BUGSNAG_KEY: "bf5b0d515b60142658aed82461fb95c7",
    AUTH_0: {
        AUTH_CLIENT_ID: 'PphBoNuxtvlNPPZehtvPUsK1Q1lnx0l6',
        AUTH_DOMAIN: 'facecheck.eu.auth0.com',
        AUTH_AUDIENCE: 'api.facecheck.ai',
        REDIRECT_URL: 'https://portal.fitforwork.ai/users',
    },
};

export default config;
