import React from "react"
import { HTMLAttributes } from "react"
import styled from "styled-components"

interface Props extends HTMLAttributes<HTMLDivElement> {
    icon: string
}

const TextWithIcon: React.FC<Props> = ({icon, children, ...props}) => {

    return <Wrapper {...props}>
        <Icon src={icon}/>
        {children}
    </Wrapper>
}

export default TextWithIcon

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const Icon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 10px;
`