import React from 'react'
import styled from 'styled-components'
import Path from 'svg-path-generator'

// const Path = require('svg-path-generator')

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    progress: number
    trackColor: string
    progressColor: string
    fontSize: string
    textOverride?: string
}

const ProgressCircle: React.FC<Props> = ({progress, trackColor, progressColor, fontSize, textOverride, ...props}) => {

    const size = 100
    const strokeWidth = 5
    const middle = (size / 2)
    const radius = (size / 2) - (strokeWidth / 2)
    const angle = (progress === 1) ? (1.9999 * Math.PI)     // If progress === 1 then the start and end point are in the same place, resulting in nothing being drawn, so we fudge it here
                                   : (progress * 2 * Math.PI)
    const startX = middle
    const startY = (strokeWidth / 2)
    const endX = (middle + (radius * Math.sin(angle)))
    const endY = (middle - (radius * Math.cos(angle)))
    const isLargeArc = (progress > 0.5) ? 1 : 0

    const path = Path().moveTo(startX, startY)
                       .ellipticalArc(radius, radius, 0, isLargeArc, 1, endX, endY)
    
    const text = textOverride ?? (progress * 100).toFixed(1) + "%"

    return <Wrapper {...props}>
        <svg viewBox={`0 0 ${size} ${size}`} vector-effect="non-scaling-stroke">
            <circle cx={middle} cy={middle} r={radius} stroke={trackColor} fill="none" strokeWidth={strokeWidth}/>
            { (progress > 0) && <path d={path} stroke={progressColor} fill="none" strokeWidth={strokeWidth} stroke-linecap="round"/>}
        </svg>
        <Text fontSize={fontSize} fontColor={progressColor}>{text}</Text>
    </Wrapper>
}

export default ProgressCircle

const Wrapper = styled.div`
    position: relative;
    flex-shrink: 0;
`

const Text = styled.div<{fontSize: string, fontColor: string}>`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    color: ${props => props.fontColor};
    text-align: center;
    font-size: ${props => props.fontSize};
`