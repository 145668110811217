import * as React from "react";
import { Datagrid, List, TextField, FunctionField, DeleteButton, Pagination } from "react-admin";
import { CellWithSignal } from '../../components/customGrid/CellWithSignal'
import LastSubmissionField from "./fields/LastSubmission";
import ViewActivityButton from "./fields/ViewActivityButton";

// NOTE: to override row features, see https://marmelab.com/react-admin/List.html#body-element for custom row styling

const UserList = (props: any) => (
	<List {...props} bulkActionButtons={false} perPage={25} pagination={<Pagination rowsPerPageOptions={[10, 25, 50]} {...props}/>} sort={{ field: 'lastName', order: 'DESC' }}>
		<Datagrid>
			<TextField source="firstName" label="First Name" />
			<TextField source="lastName" label="Last Name" />
			<TextField source="phoneNumber[0]" label="Number" sortable={false}/>
			<FunctionField
				source="sms.status"
				label="Is Invited?"
				render={(record: any) => {

					const isInvited = (record.sms?.status === 'SUCCESS')
					const text = isInvited ? 'Invited' : 'Not Invited'
					const signal = isInvited && record.lastSubmission ? "Green"
														  : isInvited ? "Amber"
																	  : "Red"

					return <CellWithSignal value={signal} text={text} />
				}}/>

			<LastSubmissionField
				label="Last submission"
				source="lastSubmissionDate"/>
			<ViewActivityButton />
			<FunctionField
				render={(record: any) => {
					return <DeleteButton undoable={false}
										 resource={props.resource}
										 record={record}
										 basePath={props.basePath}
								     	 confirmTitle={`Are you sure you want to delete ${record.firstName + " " + record.lastName}?`}
								  		 confirmContent={"This operation cannot be undone"}/>
				}}
			/>
		</Datagrid>
	</List>
);

export default UserList;
