import Axios from "axios"
import { downloadCSV } from "ra-core"
import config from "../config"
import { TrainingPage, TrainingReport } from "../types"
import { getAccessToken } from "./auth.service"
import jsonExport from 'jsonexport/dist';
import { sentenceCase } from "sentence-case"

export const downloadTrainingReport = async (tenantId: string) => {

    let isSuccessful = true
    const allUsers = [] as TrainingReport[]
    const pageSize = 100
    let currentPage = 1
    let shouldContinue = true

    try {
        while (shouldContinue) {
            const url = `${config.API_URL}/tenants/${tenantId}/report/expectations/training?pagesize=${pageSize}&page=${currentPage}`

            const response = await Axios.request<TrainingPage>({
                method: "get",
                url,
                headers: { Authorization: "Bearer " +  getAccessToken() }
            })

            if (response.data) {
                allUsers.push(...response.data.users)
            }

            shouldContinue = ((currentPage * pageSize) < (response?.data?.totalCount ?? 0))
            currentPage++
        }

        isSuccessful = (allUsers.length > 0)
    }
    catch (e) {
        isSuccessful = false
    }

    if (isSuccessful) {
        
        const headers = [
            "firstName",
            "lastName",
            "isDue",
            "externalId",
        ]

        for (const r of allUsers[0].report) {
            headers.push(`${r.displayName}.isDue`)
            headers.push(`${r.displayName}.lastSubmitted`)
            headers.push(`${r.displayName}.isSuccess`)
        }

        jsonExport(
            allUsers.map(u => {
                const row = {
                    firstName: u.firstName,
                    lastName: u.lastName,
                    isDue: u.isDue,
                    externalId: u.externalId,
                } as Record<string, any>

                for (const r of u.report) {
                    row[`${r.displayName}.isDue`] = r.isDue
                    row[`${r.displayName}.lastSubmitted`] = r.lastSubmitted
                    row[`${r.displayName}.isSuccess`] = r.isSuccess
                }

                return row
            }),
            {
                headers,
                mapHeaders: (header: string) => sentenceCase(header),
            },
            (err: any, csv: string) => downloadCSV(csv, `report-${+new Date()}`),
        );
    }

    return isSuccessful
}

// export type TrainingItem = {
//     displayName: string
//     isDue: boolean
//     lastSubmitted?: string
//     isSuccess?: boolean
// }