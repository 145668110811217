export enum Color {
    white = '#FFFFFF',

    green = '#66FFCB',
    green2 = '#B2FFE5',
    green4 = '#D8FFF2',
    green8 = '#EBFFF8',
    green16 = '#F5FFFB',

    blue = '#6699FF',
    blue2 = '#B2CCFF',
    blue4 = '#D8E5FF',
    blue8 = '#EBF2FF',
    blue16 = '#F5F8FF',

    amber = '#FFC700',

    red = '#FF2063',
    red2 = '#FF8FB1',
    red4 = '#FFC7D8',
    red8 = '#FFE3EB',
    red16 = '#FFF1F5',

    black = '#22223B',
    grey = '#59596C',
    grey2 = '#ACACB5',
    grey4 = '#D5D5DA',
    grey8 = '#EAEAEC',
    grey16 = '#F4F4F5',

    lightGrey = '#FAFAFA',
    lightGrey2 = '#636374',

    translucentBlack = `#22223B90`,

    transparent = '#FFFFFF00',
}