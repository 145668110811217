import edit from "./Edit";
import { Settings as icon }  from "@material-ui/icons";
import { ErrorCard } from '../../components/errorCard'

export const settings = (permissions: any) => ({
    name: "settings",
    edit: permissions.find((role:string) => role === 'Admin') ? edit : ErrorCard,
    icon,
    options: {
        label: "Settings",
        defaultPath: "settings/me",
    }
})