import * as React from "react";
import moment from "moment";
import Tooltip from '@material-ui/core/Tooltip';

const LastSubmissionField: React.FC<any> = ({ record = {}, source, ...rest }) => {
    const lastSubmission = record.lastSubmission
    const lastSubmissionDate = record.lastSubmissionDate

    if (!lastSubmission) {
        return <span>never</span>
    }
    return (
        <Tooltip title={lastSubmission.questionSetTitle || "n/a"}>
            <span>{moment(lastSubmissionDate).format(`HH:mm DD MMM yyyy`)}</span>
        </Tooltip>
    )
}

export default LastSubmissionField;