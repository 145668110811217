import axios from 'axios'
import config from '../config';
import { Tenant } from '../types';

const { AUTH_0, API_URL } = config;

export async function login(username: string, password: string, setStorage = true) {
	await logout()
	const response = await axios({
		method: 'post',
		url: `https://${AUTH_0.AUTH_DOMAIN}/oauth/token`,
		data: {
			username,
			password,
			grant_type: 'password',
			audience: AUTH_0.AUTH_AUDIENCE,
			client_id: AUTH_0.AUTH_CLIENT_ID,
			scope: 'openid'
		},
		headers: { 'Content-Type': 'application/json' },
	})
	const { expires_in, access_token } = response.data
	let expiresAt = JSON.stringify((expires_in * 1000) + new Date().getTime());

	const { data: auth0Data } = await axios({
		method: 'get',
		url: `https://${AUTH_0.AUTH_DOMAIN}/userinfo`,
		headers: { 'Authorization': `Bearer ${access_token}` }
	})

	const { data: ffwData } = await axios({
		method: 'get',
		url: `${API_URL}/settings/me`,
		headers: { 'Authorization': `Bearer ${access_token}` }
	})

	if (setStorage) {
		sessionStorage.setItem('expires_at', expiresAt)
		sessionStorage.setItem('access_token', access_token)
		sessionStorage.setItem('profile', JSON.stringify(auth0Data))
		sessionStorage.setItem('username', username)

		if (ffwData) {
			sessionStorage.setItem('firstName', ffwData.firstName)
			sessionStorage.setItem('lastName', ffwData.lastName)
			sessionStorage.setItem('adminId', ffwData.adminId)

			if (ffwData.satisfaction) {
				sessionStorage.setItem('satisfaction', JSON.stringify(ffwData.satisfaction))
			}

			if (ffwData.tenants) {
				sessionStorage.setItem('tenants', JSON.stringify(ffwData.tenants))
			}
		}
	}
	return true
}

export const getCurrentUsername = () => sessionStorage.getItem("username") || "";

export async function logout() {
	sessionStorage.clear();
}

export function isAuthenticated() {

	let expiresAt = JSON.parse(sessionStorage.getItem('expires_at') as string);
	let accessToken = sessionStorage.getItem('access_token');
	let profile = sessionStorage.getItem('profile');
	return (new Date().getTime() < expiresAt && accessToken && profile);
}

export function getAccessToken() {
	return sessionStorage.getItem('access_token')
}

export function getTenantId() {

	const json = sessionStorage.getItem('profile')
	const profile = json ? JSON.parse(json) : undefined

	return profile ? profile['https://facecheck.ai/tenantId']
				   : undefined
}

export function getTenants() {

	const json = sessionStorage.getItem('tenants')
	return json ? JSON.parse(json) as Tenant[]
				: undefined
}

export function userHasNewDashboard() {
	// For now, there will only ever be one top level tenant - BC, 30 Aug 2021
	const tenants = getTenants()
	return tenants?.length ? tenants[0].siteConfig?.dashboardConfig.hasDashboard
						   : false
}

export function userHasNewBranding() {
	// For now, there will only ever be one top level tenant - BC, 30 Aug 2021
	const tenants = getTenants()
	let hasNewBranding: boolean | undefined = false

	if (tenants?.length) {
		var config = tenants[0].siteConfig
		hasNewBranding = config?.dashboardConfig.hasDashboard
			  		  || config?.activityConfig.hasActivity
					  || config?.hasTraining
					  || config?.hasUsers
	}

	return (hasNewBranding === true)
}

export function usesWettonLogin() {
	// For now, there will only ever be one top level tenant - BC, 30 Aug 2021
	const tenants = getTenants()
	return tenants?.length ? tenants[0].siteConfig?.isWetton
						   : false
}

export function getLocalProfile() {
	return sessionStorage.getItem('profile');
}

export function getFirstName() {
	return sessionStorage.getItem('firstName')
}

export function getLastName() {
	return sessionStorage.getItem('lastName')
}

export function getAdminId() {
	return sessionStorage.getItem('adminId')
}

export function getSatisfaction() {
	const json = sessionStorage.getItem('satisfaction')
	console.log(json)
	return json ? JSON.parse(json) as Record<string, number>
				: undefined
}

export type SatisfactionUpdate= {
	siteId: string
	satisfaction: number
}

export function persistSatisfaction({ siteId, satisfaction }: SatisfactionUpdate) {

	const satisfactionBySiteId = getSatisfaction()

	if (satisfactionBySiteId) {
		const updatedSatisfactionBySiteId = { ...satisfactionBySiteId, [siteId]: satisfaction}
		sessionStorage.setItem('satisfaction', JSON.stringify(updatedSatisfactionBySiteId))
	}
}

// export async function changePassword(oldPassword: string, newPassword: string, newPasswordConfirm: string) {
// 	await login(getCurrentUsername(), oldPassword)

// 	if (isAuthenticated()) {
// 		const response = await axios({
// 			method: 'put',
// 			url: `${API_URL}/settings/me`,
// 			headers: { 'Authorization': `Bearer ${getAccessToken()}` },
// 			data: {
// 				id: "me",
// 				password: newPassword,
// 				passwordConfirm: newPasswordConfirm,
// 			}
// 		})

// 		return (response.status >= 200 && response.status <= 299) ? true
// 																  : false
// 	}
// 	else {
// 		return false
// 	}
// }