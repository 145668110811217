import React, { HTMLAttributes } from "react"
import styled from "styled-components"
import { Color } from "../../styles/color"
import { TitleWithNumber as _TitleWithNumber, CardWithLoading as _CardWithLoading } from "../molecules"

interface Props extends HTMLAttributes<HTMLDivElement> {
    title: string
    number: number
    isLoading: boolean
    error?: string
}

const ColumnListCard: React.FC<Props> = ({isLoading, error, title, number, children, ...props}) => {
    return <Card isLoading={isLoading} error={error} loadingColor={Color.green} {...props}>
        <TitleWithNumber title={title} number={number}/>
        <List>
            {children}
        </List>
    </Card>
}

export default ColumnListCard

const Card = styled(_CardWithLoading)`
    display: flex;
    flex-direction: column;
`

const TitleWithNumber = styled(_TitleWithNumber)`
    margin-bottom: 25px;
`

const List = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin: 0 -25px -15px -25px;
    padding: 0 25px;
`