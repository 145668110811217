import React from 'react'

import {Typography} from '@material-ui/core';
import styled from 'styled-components'
import { Color } from '../../styles/color'

type CustomLabelTypographyProps = {
    labelText: string;
    count:number
};

export const CustomLabelTypography  = ({ labelText, count }: CustomLabelTypographyProps) => {
    return (
        <Typography component='div'>
            {labelText} <Highlight>({count})</Highlight>
        </Typography>
        )
    }

const Highlight = styled.span`
    color: ${Color.blue}
`
