import React from "react"
import { HTMLAttributes } from "react"
import styled from "styled-components"
import { Color } from "../../styles/color"
import { Signal } from "../customGrid/CellWithSignal"

interface Props extends HTMLAttributes<HTMLDivElement> {
    signal: Signal
}

const TextWithSignal: React.FC<Props> = ({signal, children, ...props}) => {

    const dotColor = (signal === "Red") ? Color.red
                 : (signal === "Amber") ? Color.amber
                 : (signal === "Green") ? Color.green
                  : (signal === "Blue") ? Color.blue
                  : (signal === "Grey") ? Color.grey16
                                        : Color.grey16

    return <Wrapper {...props}>
        <Dot backgroundColor={dotColor}/>
        {children}
    </Wrapper>
}

export default TextWithSignal

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Dot = styled.div<{backgroundColor: string}>`
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background: ${props => props.backgroundColor};
    margin-right: 10px;
`