//import indigo from '@material-ui/core/colors/indigo';
//import red from '@material-ui/core/colors/red';
import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import { Color } from './color';

interface ThemeConfig {
	color: { [color: string]: string }
	img: { [url: string]: string }
}

const configWork: ThemeConfig = {
	color: {
		primary: Color.green,
		greenSignal: Color.green,
		amberSignal: Color.amber,
		redSignal: Color.red,
		main: Color.green,
		actionText: '#3D424A', // e.g button, icons,  
		titleText: '#3D424A', // e.g button, icons,  
		text: '#999C9E',
	},
	img: {
		loginBg: '/images/dots.png',
		logo: '/images/logo.svg',
		stats1: '/images/stats1.svg',
		stats2: '/images/stats2.svg',
		stats3: '/images/stats3.svg' 
	}
}

const configSport: ThemeConfig = {
	color: {
		...configWork.color,
		primary: '#F9DB5F',
		main: '#F9DB5F',
	},
	img:{
		...configWork.img,
		loginBg: '/images/cloud_sport.svg',
		logo: '/images/logo_ffsport.svg'
	}
}

const configTravel: ThemeConfig = {
	color: {
		...configWork.color,
		primary: '#7EC1EB',
		main: '#7EC1EB',
	},
	img:{
		...configWork.img,
		loginBg: '/images/cloud_travel.svg',
		logo: '/images/logo_fftravel.svg'
	}
}

export const getThemeConfig = () =>   {
    switch ((window as any).THEME) {
        case "sport":
            return configSport;
        case "travel":
            return configTravel;
			default:
				return configWork;
	}
}

function themeBuilder(): any {
	// You can set global themes here to override Material UI (see MUI docs)
	const config = getThemeConfig() // get the current brand colors
	const themeOptions: ThemeOptions = {		
		palette: {
			primary: { main: config.color.actionText },
			secondary: {
				main: config.color.primary,			
			},
			action:{
				active: config.color.actionText
			},
			error: { main: config.color.redSignal },
			contrastThreshold: 3,
			tonalOffset: 0.2,
		},
		typography: {
			// Use the system font instead of the default Roboto font.
			fontFamily: "'Hero New', Roboto, sans-serif",
			button: {
				textTransform: "none",
			},
		},
		// override the styles of all instances of this component
		overrides: {
			MuiTableRow: {
				root:{
					"& > td ": {					
						color: config.color.text,
					}
				},
				head: {				
					backgroundColor: 'none',
					"& > th ": {
						fontWeight: '500',
						color: config.color.text,
					}
				}			
			},
			MuiToolbar: {
				root:{
					color: config.color.actionText
				}			
			},
			MuiButton: {			
				textPrimary:{
					color: config.color.actionText
				}
			},
			MuiGrid:{
				root:{				
					"& > *":{
						color: config.color.actionText,
						// fontFamily: "Nunito Sans, Roboto, sans-serif",
					}
				}
			},
			MuiCardActions:{
				root: {padding:"16px 16px 18px"}
			},
			MuiFormLabel:{
				root: {
					"&.Mui-focused": {
						color: `${config.color.actionText}`
					}
				}
			},
			MuiInput:{
				underline: {
					"&:after": {
						borderBottom: `2px solid ${config.color.primary}`
					}
				}
			},
			MuiInputBase: {
				root:{
					input: {
						padding: '8px 0 7px'
					},
					focused: {
						fontFamily: "'Hero New', Roboto, sans-serif"
					}
				}
				
			}
		},
		
	}
	return themeOptions
}


export const theme = createMuiTheme( themeBuilder() );