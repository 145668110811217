import React from 'react'
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Head from './Head'
import Body from './Body'

const AnswersTable = ({data, columns, action}: any) => {
    const updateProps = {
        data,
        action: action || null,
        columns
    }
    return (
        <>
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                    <Head columns={updateProps.columns}/>     
                    <Body {...updateProps}/>
                </Table>
            </TableContainer>
        </>
    )
}

export default AnswersTable

