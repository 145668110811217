import React from "react"
import { HTMLAttributes } from "react"
import styled from "styled-components"
import { AnswerSet, Location } from "../../types"
import { Color } from "../../styles/color"
import { TextWithSignal, Map } from "../molecules"
import getDisplayForAnswer from "../../providers/answerDisplay"

interface Props extends HTMLAttributes<HTMLDivElement> {
    answerSet: AnswerSet
    ignoresFaceId?: boolean
    mockLocation?: Location
    pinColor?: "red" | "green" | "blue" | "yellow"
    isValveSensor?: boolean
}

const AnswerDetails: React.FC<Props> = ({answerSet, ignoresFaceId, mockLocation, pinColor, isValveSensor, ...props}) => {

    // const extraSucceeded = answerSet.extraFaceResults?.some(fr => fr.faceIsMatchConfidence > 80)
	// const extraFailed = (!extraSucceeded && answerSet.extraFaceResults?.length)
	// const initialSucceeded = ((answerSet?.faceResult?.faceIsMatchConfidence ?? 0) > 80)
	// const initialFailed = (!initialSucceeded && answerSet?.faceResult)

    // const needsIdHeader = (extraSucceeded || extraFailed || initialSucceeded || initialFailed)
    // const idHeaderText = extraSucceeded ? "Identified after questions"
    //                  : initialSucceeded ? "Identified before questions"
    //                       : extraFailed ? "Not identified after questions"
    //                     : initialFailed ? "Not identified before questions"
    //                                     : undefined
    // const idHeaderSignal = (extraSucceeded || initialSucceeded) ? "Green"
                                                                // : "Red"

    const location: Location = answerSet.location ?? mockLocation
    const marker = pinColor ? { ...location, color: pinColor }
                            : location

    return <Wrapper {...props}>
        <Border/>
        {location && <Map defaultCenter={location}
             markers={[marker]}
             zoom={15}
             loadingElement={<MapContainer />}
			 containerElement={<MapContainer />}
			 mapElement={<MapContainer />}
			 googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC_1Yl3_XRRLfHLewTmKBXe1JA-A-n96bg&v=3.exp&libraries=geometry,drawing,places"/>}

        {/* {!ignoresFaceId && needsIdHeader && <IdHeader signal={idHeaderSignal}>{idHeaderText}</IdHeader>} */}
        <Table>
            {answerSet.questionsAnswered.map(a => {

                const display = getDisplayForAnswer(a, isValveSensor ?? false)
                return <tr>
                    <Cell>{display.questionText}</Cell>
                    <Cell>{display.answerText}</Cell>
                    <Cell><TextWithSignal signal={display.answerSignal}>{display.answerSignalText}</TextWithSignal></Cell>
                    {/* {!ignoresFaceId && <Cell><TextWithSignal signal={display.idSignal}>{display.idText}</TextWithSignal></Cell>} */}
                </tr>
            })}
        </Table>
    </Wrapper>
}

export default AnswerDetails

const Wrapper = styled.div`
    background: ${Color.white};
    padding: 0 20px;
    display: flex;
    flex-direction: column;
`

const Border = styled.div`
    background: ${Color.grey4};
    height: 1px;
    /* margin-left: 20px; */
    margin-bottom: 20px;
`

const Table = styled.table`
    margin-bottom: 20px;
`

const Cell = styled.td`
    padding: 10px 0;
    white-space: pre-wrap;
    line-height: 1.5em;
`

// const IdHeader = styled(TextWithSignal)`
//     margin: 10px 0 20px 0;
//     justify-content: center;
// `

const MapContainer = styled.div`
    width: 300px;
    height: 300px;
    border-radius: 10px;
    margin: 0 auto 20px auto;
    /* flex-grow: 1; */
    //box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
`