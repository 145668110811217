import React from "react"
import styled from "styled-components"
import { Color } from "../../styles/color"
import { Text } from "../atoms"
import { CardWithLoading, LinkCard } from "../molecules"
import tick from "../../images/tick-blue.svg"
import exclamation from "../../images/exclamation-mark-red.svg"
import iAuditor from "../../images/iAuditor-icon.svg"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    title: string
    onTimeFraction: number
    overdueFraction: number
    isLoading: boolean
    error?: string
    hasLink: boolean
}

const DueOverdueCard: React.FC<Props> = ({isLoading, error, title, onTimeFraction, overdueFraction, hasLink, ...props}) => {

    return <CardWithLoading isLoading={isLoading} error={error} loadingColor={Color.green} {...props}>
        <TitleWrapper>
            <Text size="large">{title}</Text>
            {hasLink && <LinkCard icon={iAuditor} text="View report" link="https://app.safetyculture.com/schedule/missed"/>}
        </TitleWrapper>
        
        <ContentWrapper>
            <Details>
                <Text>On time</Text>
                <Icon src={tick}/>
                <Text size="large" color={Color.blue}>{(onTimeFraction * 100).toFixed(1) + "%"}</Text>
            </Details>
            <Line/>
            <Details>
                <Text>Overdue</Text>
                <Icon src={exclamation}/>
                <Text size="large" color={Color.red}>{(overdueFraction * 100).toFixed(1) + "%"}</Text>
            </Details>
        </ContentWrapper>
        
    </CardWithLoading>
}

export default DueOverdueCard

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
`

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
`

const Line = styled.div`
    width: 1px;
    background: ${Color.grey8};
    align-self: stretch;
`

const Icon = styled.img`
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin: 20px;
`

const Details = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`