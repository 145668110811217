import React, { FC } from 'react'
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { TopToolbar } from 'react-admin';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const BackButton = (props: any) => (
	<Button
		startIcon={<ArrowBackIcon/>}
		size="small"
		color="primary"
		component={Link}
		to={{
			pathname: props.basePath
		}}
	>
		Back
	</Button>
);

interface CustomTopToolbarProps {
	basePath: any
}

export const CustomTopToolbar: FC<CustomTopToolbarProps> = ({ basePath }) => (
	<TopToolbar>
		<BackButton basePath={basePath} />
	</TopToolbar>
)

