// import React from "react"
import styled from "styled-components"
import { Color } from "../../styles/color"

const PrimaryButton = styled.div<{ isEnabled: boolean }>`
    background-color: ${Color.green};
    color: ${Color.black};
    width: 200px;
    height: 40px;
    border-radius: 5px;
    text-align: center;
    line-height: 40px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
    opacity: ${props => props.isEnabled ? 1 : 0.3};

    &:hover {
        cursor: ${props => props.isEnabled ? "pointer" : "default" };
    }
`

export default PrimaryButton