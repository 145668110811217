import React from 'react'
import styled from 'styled-components'
// import useWindowSize from '../../hooks/useWindowSize'
import dots from '../../images/dots-top-right.png'
import logo from '../../images/logo.svg'
import wettonLogo from "../../images/wetton-logo.jpeg"
import rectangles from '../../images/rectangular-blobs.svg'
// import clockFace from '../../images/clock-face.svg'
import mortarBoard from '../../images/mortar-board.svg'
import heartbeat from '../../images/heartbeat.svg'
import iauditor from "../../images/iAuditor-icon.svg"
import speechBubbles from "../../images/speech-bubbles.svg"
import user from "../../images/user.svg"
import message from "../../images/message.svg"
import { MenuItem } from '../molecules'
import { Color } from '../../styles/color'
import { useHistory } from 'react-router-dom'
import { MenuOption, SiteConfig } from '../../types'
import { usesWettonLogin } from '../../providers/auth.service'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    options: MenuOption[]
    selectedOption: string
}

const Menu: React.FC<Props> = ({options, selectedOption, ...props}) => {

    // const { width } = useWindowSize()

    const history = useHistory()

    //return <Wrapper width={width > 400 ? "250px" : "100vw"}>
    return <Wrapper width="300px" {...props}>
        {usesWettonLogin() ? <WettonLogo src={wettonLogo}/>
                           : <Placeholder/>}
        {options.map(o => <MenuItem icon={o.icon} key={(o.label).replace(/\s/g, '')}
                                    isSelected={selectedOption === o.label}
                                    isMinor={o.isMinor}
                                    text={o.label}
                                    onClick={() => o.isExternal ? window.open(o.url, "_blank")
                                                                : history.push(o.url)}/>)}
        <FFWLogo src={logo}/>
        <Dots src={dots}/>
    </Wrapper>
}

export default Menu

export const menuOptionsForConfig = (config: SiteConfig) => {
    // show messaging for Larin tenant only
    // const tenant = getTenants() as Tenant[]
    // const tenantId = tenant[ 0 ].tenantId
    const menuOptions = [] as MenuOption[]
    if (config.dashboardConfig.hasDashboard) {
        menuOptions.push({icon: rectangles, isMinor: false, label: "Dashboard", isExternal: false, url: "/dashboard"})
    }

    if (config.activityConfig.hasActivity) {
        menuOptions.push({icon: heartbeat, isMinor: false, label: "Recent activity", isExternal: false, url: "/activity"})
    }

    if (config.hasTraining) {
        menuOptions.push({icon: mortarBoard, isMinor: false, label:  "Training records", isExternal: false, url: "/training"})
    }

    if (config.helpdeskLink) {
        menuOptions.push({icon: speechBubbles, isMinor: false, label: "Helpdesk", isExternal: true, url: config.helpdeskLink})
    }

    if (config.hasUsers) {
        menuOptions.push({icon: user, isMinor: false, label: "Manage users", isExternal: false, url: "/users/manage"})
    }
    // if (tenantId === 'faaddb74-5230-48ca-bdff-6d99563f44d3' || tenantId === '29afcc76-02cb-44dd-8617-e4edeea47b3d') {
        menuOptions.push({ icon: message, isMinor: false, label: "Messaging", isExternal: false, url: "/messaging" })
    // }
    if (config.iAuditorLinks?.length) {
        menuOptions.push({icon: iauditor, isMinor: false, label: "iAuditor links", isExternal: true, url: "https://app.safetyculture.com/inspections"})

        for (const l of config.iAuditorLinks) {
            menuOptions.push({isMinor: true, label: l.label, isExternal: true, url: l.url})
        }
    }

    return menuOptions
}

// const iconForOption = (o: MenuOption) => {
//     return o === "Recent activity" ? heartbeat
//                : o === "Dashboard" ? rectangles
//         : o === "Training records" ? mortarBoard
//           : o === "iAuditor links" ? iauditor
//         : o === "Provide feedback" ? speechBubbles
//             : o === "Manage users" ? user
//                                    : ""
// }

// const linkForOption = (o: MenuOption) => {
//     return o === "Recent activity" ? "/activity"
//                : o === "Dashboard" ? "/dashboard"
//         : o === "Training records" ? "/training"
//             : o === "Manage users" ? "/users/manage"
//                                    : ""
// }

// const isMinorOption = (o: MenuOption) => {
//     return o === "Quality reports"
//         || o === "Emergency lighting"
//         || o === "Check cleans"
// }

const Wrapper = styled.menu<{width: string}>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${props => props.width};
    height: 100vh;
    background: ${Color.white};
    padding: 30px;
    box-shadow: 0px 8px 24px rgba(34, 34, 59, 0.15);
    box-sizing: border-box;
`

const Dots = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 320px;
    object-fit: contain;
    pointer-events: none;
`

const WettonLogo = styled.img`
    margin-bottom: 100px;
    width: 55px;
`

const Placeholder = styled.div`
    height: 180px;
`

const FFWLogo = styled.img`
    margin-top: auto;
    margin-bottom: 20px;
    width: 55px;
`