export { PercentageTile } from './percentage/PercentageTile'
export { ProgressTile } from './progress/ProgressTile'

export const asPercent = (amount:number, total:number) => {
    const number = (Math.round(amount / total * 100));
    if (isNaN(number)) {
        return 0;
    }
    if (number === Infinity) {
        return 100;
    }
    return number;
}