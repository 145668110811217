import React from 'react';
import { Grid as _Grid } from '@material-ui/core';
import styled from "styled-components";
import { Login as _Login, LoginForm } from 'react-admin';
import { getThemeConfig } from '../../styles/theme';


export const LoginContainer = (props: any) => (
	<Grid container={true} alignItems={"center"} justify={"center"}>
		<Container>
			<h1>Login</h1>
			<Login {...props}>
				<LoginForm redirectTo="/landing"/>
			</Login>
			<BackgroundImage src={themeConfig.img.loginBg}/>
		</Container>
	</Grid>
)

const themeConfig = getThemeConfig()

const Container = styled.div`	
	width: 37em;
	height: 30em;
	color: inherit;
	h1{
		text-align: center;
		font-weight: bold;
		font-size: 1.3rem;		
	}
`

const Login = styled(_Login)`
	margin: 0!important;
	padding: 0!important;
	border: 0!important;
	box-sizing: border-box!important;
	min-height: 80%!important;
	background-image: none!important;
	[class^="MuiPaper-root-"]{
		margin-top: 2em;
		button{
			border-radius: none;
			height: 3em;
			color: ${themeConfig.color.actionText};
			font-weight: bold;
			background-color: ${themeConfig.color.main};
		}
	}
`

const BackgroundImage = styled.img`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-55%);
	z-index: -1;
	max-height: 480px;
`

const Grid = styled(_Grid)`
	height: 100vh;
	width: 100vw;
	top: 0;
	bottom: 0;    
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;      
`





