import axios from "axios"
import React, { useEffect } from "react"
import { getAccessToken } from "../providers/auth.service"

export const useWebGet = <TResponse>(url: string, isReady: boolean) => {

    const [ isLoading, setIsLoading ] = React.useState(false)
    const [ errorCode, setErrorCode ] = React.useState<number | undefined>(undefined)
    const [ data, setData ] = React.useState<TResponse | undefined>(undefined)
    const [ isRetriggered, setIsRetriggered ] = React.useState(false)

    useEffect(() => {

        // console.log("useWebGet useEffect")
        // console.log([ url, isReady, isLoading, isRetriggered ])

        const makeGetCall = async () => {
            if (isReady) {
                try {
                    setIsLoading(true)
                    const response = await axios.request<TResponse>({
                        method: "get",
                        url,
                        headers: { Authorization: "Bearer " +  getAccessToken() }
                    })

                    if (response.status >= 200 && response.status <= 299) {
                        setData(response.data)
                        setErrorCode(undefined)
                    }
                    else {
                        setErrorCode(response.status)
                        setData(undefined)
                    }

                    setIsLoading(false)
                }
                catch (e) {
                    setErrorCode(-1)
                    setData(undefined)
                    setIsLoading(false)
                }

                setIsRetriggered(false)
            }
        }

        makeGetCall()
    }, [ url, isReady, isRetriggered ])

    return {
        isLoading,
        errorCode,
        data,
        refetch: () => setIsRetriggered(true),
    }
}