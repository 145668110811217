import React, { FC } from 'react'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { AnswerNameCell } from "./AnswerNameCell";
import { AnswerRecord, AnswerStatusOf } from '../../resources/responses/types';
import { useHistory } from 'react-router-dom';
import { linkToRecord } from 'ra-core';
import styled from 'styled-components';

interface RowGrid {
	record?: AnswerRecord
	resource?: string,
	id?: any,
	children?: any,
	basePath?: string,
	rowClick?: any
}

export const AnswersRow: FC<RowGrid> = ({
	record,
	resource,
	id,
	children,
	basePath,
}) => {
	const history = useHistory();
	const handleClick = (e: React.MouseEvent) => {
		const cellText = document.getSelection()!;
		// check for any highlighted text
  		if ( cellText.type === 'Range' ) {
			  e.stopPropagation()
		} else {
			history.push(linkToRecord(basePath, id, 'show'))
		}
	};
	return (
		<Row key={id} hover={true} onClick={handleClick}>
			{React.Children.map(children, field => {
				const { props } = field
				if (props.label === 'Name') {

					return (
						<TableCell padding="none" >
							<AnswerNameCell answerStatus={AnswerStatusOf(record)} source={record?.userName} />
						</TableCell>
					);
				}
				return (
					<TableCell key={`${id}-${field.props.source}`}>
						{React.cloneElement(field, {
							record,
							basePath,
							resource,
						})}
					</TableCell>
				)
			})}
		</Row>
	);
}

const Row = styled(TableRow)`
	&:hover {
		cursor: pointer;
	}
`;