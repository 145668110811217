import React from "react"
import { withGoogleMap, withScriptjs, GoogleMap, Marker } from "react-google-maps"

interface Props {
	defaultCenter: Location
	center?: Location
	zoom: number
    markers: (Location & { color?: "red" | "green" | "blue" | "yellow" })[]
}

type Location = {
    latitude: number;
	longitude: number;
}

const _Map: React.FC<Props> = ({ defaultCenter, center, zoom, markers }) => {
	
	const centerToUse = {lat: center?.latitude ?? defaultCenter.latitude, lng: center?.longitude ?? defaultCenter.longitude}
	const options = {
		fullscreenControl: false,
		mapTypeControl: false,
		streetViewControl: false,
		zoomControl: false,
	}

	return <GoogleMap defaultZoom={16} center={centerToUse} defaultCenter={defaultCenter} zoom={zoom} defaultOptions={options}>
		{markers.filter(m => !!m).map(m => <Marker position={{ lat: m.latitude, lng: m.longitude }} icon={
			 m.color === "green" ? "https://maps.google.com/mapfiles/ms/icons/green-dot.png"
			: m.color === "blue" ? "https://maps.google.com/mapfiles/ms/icons/blue-dot.png"
			 : m.color === "red" ? "https://maps.google.com/mapfiles/ms/icons/red-dot.png"
		  : m.color === "yellow" ? "https://maps.google.com/mapfiles/ms/icons/yellow-dot.png"
								 : undefined
		}/>)}
	</GoogleMap>
};

const Map = withScriptjs(withGoogleMap(_Map))

export default Map;
