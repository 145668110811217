import config from '../../config'
import {
	DateInput,
	SimpleForm as _SimpleForm,
	downloadCSV,
	required,
	useNotify,
} from "react-admin";
import Axios from 'axios';
import React from "react"
import styled from 'styled-components';

const ReportDownloader = (props: any) => {
	
	const notify = useNotify();

	return <Container>
		<SimpleForm save={(r: any) => downloadReport(r, notify)} class={"MuiPaper-elevation1 MuiPaper-rounded MUIPaper-root MUICard-root"}>
			<DateInput label="From" source="from" validate={[required()]} initialValue={new Date()}/>
			<DateInput label="To" source="to" validate={[required()]} initialValue={new Date()}/>
		</SimpleForm>
	</Container>
}

// TODO: This belongs in a data provider
const downloadReport = async (r: any, notify: any) => {

	const profile = JSON.parse(sessionStorage.getItem('profile') as string)
	const accessToken = sessionStorage.getItem('access_token')

	try {
		const response = await Axios({
			method: 'get',
			url:`${config.API_URL}/tenants/${profile['https://facecheck.ai/tenantId']}/report/expectations`,
			params: { start: r.from, end: r.to },
			headers: { Authorization: "Bearer " +  accessToken}
		})

		if (response.status === 200) {
			notify("Downloaded")
		}

		downloadCSV(response.data, `report-${+new Date()}`)
	}
	catch (Error) {
		notify("Reports can cover a max of 31 days.", "warning")
	}
}

export default ReportDownloader

const Container = styled.div`
	background: white;
	margin-top: 1em;

	// input[type=date] {
	// 	&:active {
	// 		color: green;
	// 	}
	// }
`

const SimpleForm = styled(_SimpleForm)`
	
`