import React, { FC } from 'react'
import { Grid as _Grid } from '@material-ui/core';
import styled from 'styled-components';
import { getThemeConfig } from '../../styles/theme';
import { AnswerStatus } from '../../resources/responses/types';

interface Props {
	answerStatus: AnswerStatus,
	source?: string
}

export const AnswerNameCell: FC<Props> = ({ answerStatus, source }) => (
	<Grid container={true} alignItems='center'>
		<SectionLeft answerStatus={answerStatus}></SectionLeft>
		<SectionRight>{source}</SectionRight>
	</Grid>
)

const themeConfig = getThemeConfig()

const Grid = styled(_Grid)`
    height: 3rem;
`
const SectionLeft = styled.div`
	border-radius: 3px 0px 0 3px;
	width: 5%;
	height: 100%;
	background-color: ${(props: Props) => (props.answerStatus === 'Unsuccessful') ? themeConfig.color.redSignal
							 	  									     		  : themeConfig.color.greenSignal};
`
const SectionRight = styled.div`
	padding-left: 5%;
	width: 90%;
`

