import axios from "axios"
import React from "react"
import { getAccessToken } from "../providers/auth.service"

export const useWebDelete = <TResponse>(onCompleted?: (isSuccess: boolean) => void) => {

    const [ isLoading, setIsLoading ] = React.useState(false)
    const [ errorCode, setErrorCode ] = React.useState<number | undefined>(undefined)
    const [ responseData, setResponseData ] = React.useState<TResponse | undefined>(undefined)
    const [ isSuccess, setIsSuccess ] = React.useState(false)

    const makeDeleteCall = async (url: string) => {

        if (url && !isLoading) {
            try {
                setIsLoading(true)
                
                const response = await axios.request<TResponse>({
                    method: "delete",
                    url,
                    headers: { Authorization: "Bearer " +  getAccessToken() },
                    validateStatus: status => true
                })

                if (response.status >= 200 && response.status <= 299) {
                    setResponseData(response.data)
                    setErrorCode(undefined)
                    setIsSuccess(true)
                    onCompleted && onCompleted(true)
                }
                else {
                    setErrorCode(response.status)
                    setResponseData(undefined)
                    onCompleted && onCompleted(false)
                }

                setIsLoading(false)
            }
            catch (e) {
                setErrorCode(-1)
                setResponseData(undefined)
                setIsLoading(false)
                onCompleted && onCompleted(false)
            }
        }
    }

    const reset = () => {
        setErrorCode(undefined)
        setIsLoading(false)
        setResponseData(undefined)
        setIsSuccess(false)
    }

    return {
        isLoading,
        isSuccess,
        errorCode,
        responseData,
        reset,
        start: (url: string) => makeDeleteCall(url),
    }
}