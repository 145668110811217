import React from 'react'
import { withStyles } from '@material-ui/core/styles';

import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);
 
const DetailsTableBody = ({data, action, columns}: any) => { 
    return <TableBody>
      {data && data.map((row: any, index: any) => {
        return (
          <StyledTableRow hover key={index} onClick={()=>action ? action({isOpen: true, data: row}) : true}>
            {columns.map((column: any) => {              
              const value = (column.key === '_root') ? row
                                                     : row[column.key] || 'N/A';
              return (
                <TableCell key={column.id} align={column.align}>                                  
                  {(value && column.format) ? column.format(value) : value}
                </TableCell>
              );
            })}
          </StyledTableRow>
        );
      })}
  </TableBody>
}
export default DetailsTableBody
