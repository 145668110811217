import React, { FC } from 'react'

import styled from "styled-components";
import { getThemeConfig } from '../../styles/theme';

interface Props {
	value?: Signal,
	text?: string
}

export type Signal = "Red" | "Amber" | "Green" | "Blue" | "Grey"

export const CellWithSignal: FC<Props> = ({ value, text }) => {
  
	return (
		<Section value={value}>{text}</Section>
	)
}

const themeConfig = getThemeConfig()

const Section = styled.div`
  /* Pass variables as inputs */
  /* Adjust the background from the properties */
  &:before {
    content: "● ";
    color: ${(props: Props) => (props.value === "Green") ? themeConfig.color.greenSignal
                             : (props.value === "Amber") ? themeConfig.color.amberSignal
                                                         : themeConfig.color.redSignal};
  }
`