import React, { FC } from 'react'
import styled from 'styled-components';
import { getThemeConfig } from '../../../styles/theme';
import { Header as _Header} from "../../../components/atoms";
import { asPercent } from '../'

export interface PercentageTile {
    title: string,
    amount: number,
    total: number,
    background?: string;
}

export const PercentageTile: FC<PercentageTile> = ({title, amount, total, background}) => {
    const percent = asPercent( amount,total )
    const bg = {backgroundImage: `url( ${background} )`}
    const isData = (amount + total) > 0;
    const label = isData ? `${amount} of ${total}` : ``;
    const percentLabel = isData ? `${percent}%` : `-`;

	return (
        <Tile style={bg}>
            <VerticallyCenter>
                <Header size={16}>{title}</Header>
                <DataPrimary size={32}>{percentLabel}</DataPrimary>
                <DataSecondary>{label}</DataSecondary>
            </VerticallyCenter>
        </Tile>
	);
}

const themeConfig = getThemeConfig()

const Tile = styled.div`
    height: 100%;
    padding: 0 0 0 98px;
    background: white;
    border-radius: 8px;
    background: #fff url( ${themeConfig.img.stats2} ) no-repeat 14px center;
    background-size: auto 63px;
    box-shadow: 0px 5px 11px -7px rgba(0,0,0,0.5);
    @media only screen and (max-width: 1024px) {
        padding: 0 0 0 72px;
        background-size: auto 45px;
    }
`;

const VerticallyCenter = styled.div`
    position: relative;
    top: 50%;
    transform: translateY(-50%);
`

const Header = styled(_Header)`
    font-weight:500
`
const DataPrimary = styled(_Header)`
    line-height: 1;
    font-weight:600;
`

const DataSecondary = styled.span`
    font-size:12px;
    color: ${themeConfig.color.text}
`;
