import React, { HTMLAttributes } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { Text } from "../atoms"

interface Props extends HTMLAttributes<HTMLDivElement> {
    icon: string
    text: string
    link: string
}

const LinkCard: React.FC<Props> = ({icon, text, link, ...props}) => {
    
    const history = useHistory()
    const isExternalLink = link.startsWith("http")

    return <Wrapper onClick={() => isExternalLink ? window.open(link, "_blank") : history.push(link)} {...props}>
        <Icon src={icon}/>
        <Text size="small" opacity="moderate">{text}</Text>
    </Wrapper> 
}

export default LinkCard

const Wrapper = styled.div`
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
    min-height: 30px;
    min-width: 130px;
    margin-left: 10px;
`

const Icon = styled.img`
    width: 15px;
    height: 15px;
    margin-right: 10px;
    margin-left: 10px;
`