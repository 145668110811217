import React from 'react'
import { withStyles } from '@material-ui/core/styles';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';

const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: '#E9E6E6',
      color: theme.palette.common.black,
    },
    body: {
      fontSize: '1em',     
    },
  }))(TableCell);
  
  
const DetailsTableHead = ({columns}: any) => {
    return (<TableHead>
      <TableRow>
          {columns.map((column: any) => {
              return (<StyledTableCell style={{ minWidth: column.minWidth, fontWeight: 'bold' }} key={column.id}>{column.label}</StyledTableCell>)
            }
          )}            
      </TableRow>
    </TableHead>)
}
export default DetailsTableHead
