import styled from "styled-components";
import { Color } from "../../styles/color";

const TertiaryButton = styled.div`
    background-color: ${Color.white};
    color: ${Color.black};
    border: 1px solid ${Color.black};
    width: 200px;
    height: 40px;
    border-radius: 5px;
    text-align: center;
    line-height: 40px;

    &:hover {
        cursor: pointer;
    }
`

export default TertiaryButton