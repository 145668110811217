import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { Text } from '../atoms'

interface Props extends HTMLAttributes<HTMLDivElement> {
    isSelected: boolean
    icon?: string
    text: string
    isMinor: boolean
}

const MenuItem: React.FC<Props> = ({isSelected, icon, text, isMinor, ...props}) => {

    return <Wrapper isMinor={isMinor} {...props}>
        <Icon src={icon} isSelected={isSelected} isVisible={!!icon}/>
        <Text weight={isSelected ? "medium" : "regular"}
              size="normal+"
              opacity={isSelected ? "full"
                        : isMinor ? "moderate"
                                  : "strong"}>
                  {text}
        </Text>
    </Wrapper>
}

export default MenuItem

const Wrapper = styled.div<{isMinor: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: ${props => props.isMinor ? "50px" : "0"};
    margin-top: ${props => props.isMinor ? "10px" : "40px"};

    &:hover {
        cursor: pointer;
    }
`

const Icon = styled.img<{isSelected: boolean, isVisible: boolean}>`
    /* visibility: ${props => props.isVisible ? "visible" : "hidden"}; */
    display: ${props => props.isVisible ? "block" : "none"};
    width: 25px;
    height: 25px;
    margin-right: 10px;
    opacity: ${props => props.isSelected ? 1 : 0.5};
`