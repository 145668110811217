import i18nProviderDefault from "react-admin/lib/defaultI18nProvider";

export default {
	...i18nProviderDefault,
	translate: (key: string, options: any) => {
		if (key === "ra.page.list" && options?.name === "Answersets") {
			return "Activity";
        }
        if (key === "ra.page.show" && options?.name === "Answerset" && options?.id) {
            return `Activity #${options.id}`;
        }
        if (key === "ra.page.edit" && options?.name === "Setting") {
            return `Change your password`;
        }
        if (key === "ra.page.list" && options?.name === "Fire-evacuations") {
            return "Fire Evacuation"
        }
        if (key === "ra.page.list" && options?.name === "Recently-evacuateds") {
            return "Recently Evacuated"
        }
        return i18nProviderDefault.translate(key, options);
	}
}
