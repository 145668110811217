import React from 'react'
import { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { Color } from '../../styles/color'
import { Text } from "../atoms"

interface Props extends HTMLAttributes<HTMLTableCellElement> {

}

const HeaderCell: React.FC<Props> = ({children, ...props}) => {

    return <Cell {...props}>
        <Text color={Color.lightGrey2} size="small">{children}</Text>
    </Cell>
}

export default HeaderCell

const Cell = styled.th`
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 8px;
    text-align: left;
    
    :first-child {
        padding-left: 20px;
    }
`